import React from 'react'

import styled from 'styled-components'

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const TextBanner = styled.div`
  padding: 8px;
`

const PlatformContainer = styled.div`
  background: radial-gradient(circle, rgba(64, 64, 64, 1) 0%, rgba(0, 0, 0, 1) 100%);
  padding: 10px;
  border-radius: 15px;
`
const Text = styled.div``

const Title = styled.div`
  font-weight: 700;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
`

const BuiltTitleText = styled.div`
  position: absolute;
  left: 40%;
  top: 5%;
  color: white;
  font-weight: 700;
`

interface Props {
  text: string
  date: string
  width: number | string
  height: number | string
  title: string
  image?: string
  logoImage?: string
  platformImage?: string
  url?: string
  screen?: string
  releaseDate?: string
}

const Content = ({
  text,
  date,
  width,
  height,
  title,
  image,
  logoImage,
  platformImage,
  url = '',
  screen,
  releaseDate,
}: Props) => {
  return (
    <>
      {screen === 'built' ? <BuiltTitleText>COMING {releaseDate}</BuiltTitleText> : null}
      <img width="100%" height="100%" src={image} alt="text" />
      {platformImage ? (
        <Center>
          <img src={logoImage} width={460 / 2} height={320 / 2} alt="poster" />
          <PlatformContainer>
            <img src={platformImage} alt="poster" />
          </PlatformContainer>
        </Center>
      ) : null}
      {text !== '' ? (
        <TextBanner
          style={{
            color: screen === 'tech' ? 'white' : 'black',
            backgroundColor: screen === 'tech' ? 'none' : 'white',
          }}>
          {title !== '' ? <Title>{title}</Title> : null}
          <Text>
            <div style={{ height: 100, fontSize: '24px' }}>{text}</div>
          </Text>
        </TextBanner>
      ) : null}
      {date !== '' ? (
        <TextBanner style={{ padding: '30px 10px', backgroundColor: 'white' }}>
          <Text>
            <div style={{ fontSize: '20px', fontWeight: 'bold', float: 'right' }}>{date}</div>
          </Text>
        </TextBanner>
      ) : null}
    </>
  )
}

const Card = (props: Props) => {
  const { width, height, url, screen } = props

  return (
    <Frame
      style={{
        width,
        height,
        margin: screen === 'built' ? '0px' : '12px',
      }}>
      {url !== '' ? (
        <a href={props.url}>
          <Content {...props} />
        </a>
      ) : (
        <Content {...props} />
      )}
    </Frame>
  )
}

export default Card
