import React from 'react'
import styled from 'styled-components'
import { Center } from '../components/layout'
import coverArt from '../assets/HT_Cover_Art.png'
import placeholder from '../assets/HT_Video_Placeholder_Image.png'
import TwitterIcon from '../assets/Social/twitter.png'
import FacebookIcon from '../assets/Social/facebook.png'
import InstagramIcon from '../assets/Social/instagram.png'
import EmailIcon from '../assets/Social/email.png'
import Footer from '../components/footer'

const VideoContainer = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(64, 64, 64, 1) 0%, rgba(0, 0, 0, 1) 100%);
  max-height: 900px;
  width: 80%;
  height: 55.55vw;
`

const Home = () => {
  return (
    <div>
      <div style={{ position: 'relative' }}>
        <img style={{ width: '100%' }} src={coverArt} alt="cover art" />
        <Center>
          {/* <VideoContainer> */}
          <img style={{ width: '100%' }} src={placeholder} alt="cover art" />

          {/* <HeroVideo src={heroVideo} autoPlay muted loop /> */}
          {/* </VideoContainer> */}
        </Center>
      </div>
      <Center style={{ fontSize: 24, color: 'white' }}>
        <div style={{ maxWidth: 702, textAlign: 'center' }}>
          Game Mechanic Studios presents our first original IP game - Happy Trails and the Kidnapped Princess. A fantasy
          adventure game that is unlike anything you have ever played before - get ready for a brand new experience on
          consoles and PC.
        </div>
        <div style={{ padding: 8, margin: 16 }}>
          <a href="https://twitter.com/studiosmechanic" target="_blank">
            <img
              style={{ backgroundColor: 'white', padding: 4, margin: 8, borderRadius: 8 }}
              width="32"
              src={TwitterIcon}
              alt="twitter icon"
            />
          </a>
          <a href="https://www.facebook.com/Game-Mechanic-Studios-109448684931971" target="_blank">
            <img
              style={{ backgroundColor: 'white', padding: 4, margin: 8, borderRadius: 8 }}
              width="32"
              src={FacebookIcon}
              alt="facebook icon"
            />
          </a>
          <a href="https://www.instagram.com/gamemechanicstudios/?utm_medium=copy_link" target="_blank">
            <img
              style={{ backgroundColor: 'white', padding: 4, margin: 8, borderRadius: 8 }}
              width="32"
              src={InstagramIcon}
              alt="instagram icon"
            />
          </a>
          <a href="https://mailchi.mp/988bf5ba99be/subscribe">
            <img
              style={{ backgroundColor: 'white', padding: 4, margin: 8, borderRadius: 8 }}
              width="32"
              src={EmailIcon}
              alt="email icon"
            />
          </a>
        </div>
      </Center>
      <Footer showHappyTrailsPressKit={false} />
    </div>
  )
}

export default Home
