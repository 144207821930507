import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, CenterH, Banner } from '../components/layout'
import firebase from 'firebase'

const styles = {
  info: {
    width: '400px',
    height: '30px',
    margin: '8px 0',
    border: 'none',
    backgroundColor: 'transparent',
  },
}

const Button = styled.div`
  background-color: #4b4c54;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.25s;

  &:active {
    transform: scale(0.75);
  }
`

const SuccessfulSubmit = styled.div`
  background-color: #4b4c54;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
`

const Error = styled.div`
  color: red;
  padding: 10px 0px;
`

const Connect = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [inquiryType, setInquiryType] = useState('Question')
  const [badName, setBadName] = useState(true)
  const [badEmail, setBadEmail] = useState(true)
  const [badMessage, setBadMessage] = useState(true)
  const [submitAttempt, setSubmitAttept] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  function updateName(e: any) {
    setName(e.target.value)
  }

  function updateEmail(e: any) {
    setEmail(e.target.value)
  }

  function updateType(e: any) {
    setInquiryType(e.target.value)
  }

  function updateMessage(e: any) {
    setMessage(e.target.value)
  }

  function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  useEffect(() => {
    name === '' ? setBadName(true) : setBadName(false)
    validateEmail(email) === false ? setBadEmail(true) : setBadEmail(false)
    message === '' ? setBadMessage(true) : setBadMessage(false)
  }, [name, message, email])

  async function send(name: string, email: string, inquiryType: string, message: string) {
    const db = firebase.firestore().collection('mail')

    try {
      db.add({
        to: email,
        message: {
          subject: 'Game Mechanic Studio Contact Support Email',
          html: `from ${name} at ${email}
          with the inquiry type of ${inquiryType}
          message :
          ${message}`,
        },
      })
      return { status: 'sent' }
    } catch (e) {
      return { status: 'failed', ...e }
    }
  }

  function submit() {
    setSubmitAttept(true)

    if (badName === false && badEmail === false && badMessage === false) {
      setSubmitted(true)
      send(name, email, inquiryType, message)
    }
  }

  return (
    <div>
      <Banner>
        <CenterH>STAY CONNECTED WITH GMS</CenterH>
      </Banner>
      <Center style={{ paddingTop: 50, backgroundColor: 'rgb(220,220,220)' }}>
        {submitted === false ? (
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <label>Name</label>
            <input
              required
              onChange={(e) => updateName(e)}
              type="text"
              id="name"
              name="name"
              style={{
                ...styles.info,
                borderBottom: '1px solid black',
                fontSize: '20px',
              }}
            />
            {submitAttempt && badName ? <Error>Required</Error> : null}

            <label>Email</label>
            <input
              required
              onChange={(e) => updateEmail(e)}
              type="email"
              id="email"
              name="email"
              style={{
                ...styles.info,
                borderBottom: '1px solid black',
                fontSize: '20px',
              }}
            />
            {submitAttempt && badEmail ? <Error>Required</Error> : null}

            <label>Inquire Type</label>
            <select
              onChange={(e) => updateType(e)}
              name="inquiryType"
              style={{
                ...styles.info,
                borderBottom: '1px solid black',
                fontSize: '20px',
              }}>
              <option value="question">Question</option>
              <option value="refund">Refund</option>
              <option value="complaint">Complaint</option>
              <option value="suggestion">Suggestion</option>
            </select>
            <label style={{ justifyContent: 'left' }}>Message</label>
            <textarea
              onChange={(e) => updateMessage(e)}
              id="message"
              name="message"
              style={{
                ...styles.info,
                border: '1px solid black',
                fontSize: '20px',
                height: 200,
                marginTop: '20px',
              }}
            />
            {submitAttempt && badMessage ? <Error>Required</Error> : null}

            <Center>
              <Button style={{ width: 100, marginTop: 50, marginBottom: 50 }} onClick={() => submit()}>
                Send Inquiry
              </Button>
            </Center>
          </form>
        ) : (
          <SuccessfulSubmit>
            {' '}
            Thank you {name}, your email has been successfully submitted!
            <br /> We will respond back to you as soon as possible to the email {email}.
          </SuccessfulSubmit>
        )}
      </Center>
    </div>
  )
}

export default Connect
