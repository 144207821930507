import React, { useContext } from 'react'
import styled from 'styled-components'
import { Center, CenterH, Banner } from '../components/layout'

import { AssetContext } from '../contexts/assetContext'
import { Link } from 'react-router-dom'

const Container = styled.div`
  background-color: white;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
`

const TermsText = styled.div`
  color: black;
  width: 90%;
`

const TermsOfService = () => {
  const assets: any = useContext(AssetContext)
  const { termsOfService }: any = assets

  return (
    <div>
      <Banner>
        <Center>TERMS OF SERVICE</Center>
      </Banner>
      <CenterH>
        <Container>
          <TermsText>
            <Center style={{ textAlign: 'center' }}>
              <strong>
                <p>Game Mechanic Studio EULA</p>
                <p> Wizards Wand of Epicosity</p>
              </strong>
            </Center>
            <p>
              <strong>Introduction.</strong>
            </p>
            <p>
              Before playing Wizards Wand of Epicosity (“
              <strong>Wizards</strong>”), you must fully read and accept this End User License Agreement (“
              <strong>Agreement</strong>”), and our <Link to="/privacypolicy">Privacy Policy</Link>. This Agreement is a
              legally binding agreement for Wizards on PC, PlayStation®4/Xbox One and related products.
            </p>
            <p>
              We grant you only a personal, limited right to play Wizards (but Game Mechanic Studios (“
              <strong>we</strong>,” “<strong>GMS</strong>,” “<strong>us</strong>” own Wizards). This Agreement contains
              re rules regarding what you can/cannot do with Wizards (e.g. don’t use exploits, cheat, try to hack the
              game, or ruin it for others).
            </p>
            <p> Our Privacy Policy explains what information about you we collect and how we protect it.</p>
            <p>
              Depending on where you live, there are important rules regarding liability and dispute resolution
              (including mandatory arbitration and waiver of jury trial if you live in the USA).
            </p>
            <p>
              <strong> 1. About this Agreement</strong>
            </p>
            <p>
              This Agreement is a legally binding contract between you, and GMS. This Agreement applies to our video
              game Wizards, any game key or code giving you access to it or any parts of it, Wizards Virtual Goods and
              Virtual Currency (each as defined below), esports tournaments and events, plus any and all official
              products and services related to Wizards, including (but not limited to) user accounts, customer and
              technical support provided by us, plus official forums, wikis, blogs and social media services (we will
              refer to “Wizards” to cover all these things). This Agreement will be binding on you and us once you
              download, install or use Wizards. If you do not agree to it, please do not use Wizards.
            </p>
            <p>
              Please note that this Agreement applies only to the users playing on PC, PlayStation®4 or Xbox One. If you
              play Wizards using a different platform, please check the User Agreement dedicated to such platform. This
              Agreement applies only to Wizards.
            </p>
            <p>
              <strong>2. Children Under the Age of Majority</strong>
            </p>
            <p>
              If you are over 18, or are old enough where you live to enter into a binding contract, then you can play
              Wizards without the permission of your parents or legal guardian. If, however you are not, ask your parent
              or guardian to review and approve this Agreement on your behalf (because in some states and countries
              people under a certain age (the “age of majority”) cannot legally enter fully into contracts like this
              Agreement). Also, if you are not of the age of majority, your parent or legal guardian should supervise
              your use of Wizards.
            </p>
            <p>
              <strong> 3. Accessing and using Wizards</strong>
            </p>
            <p>
              We Grant you a personal, limited, revocable, non-exclusive, non-transferable and non-assignable license to
              display, view, download, install, play and use Wizards on authorized devices/platforms. This license is
              for your personal use only (so you cannot give, ‘sell’, lend, gift, assign, sub-license or otherwise
              transfer it to someone else) and does not give you any ownership rights in Wizards. Please note that your
              use of Wizards on your chosen platform may be subject to other age limitations described in terms and
              conditions of respective platform.
            </p>
            <p>
              <strong>4. User Accounts</strong>
            </p>
            <p>
              In order to play Wizards, you will need to create a user account for your chosen platform or use an
              existing account (if you have one). Please bear in mind these services have their own legal documents.
              Your respective platforms are responsible for your use of their accounts and other services and we are
              sure they can help you out with any issues or queries. You are responsible for protecting your user
              account and for your account activities. In particular, keep your password secure.
            </p>
            <p>
              <strong> 5. Minimum Requirements</strong>
            </p>
            <p>
              Wizards will have minimum requirements depending on your chosen device/system, which you will be notified
              of. Please make sure you meet these requirements before downloading the game! To play Wizards online you
              will need Internet access too (which is your responsibility to obtain and maintain).
            </p>
            <p>
              <strong>6. Patches, Updates and Changes</strong>
            </p>
            <p>
              We may update or change Wizards over time (for example to add or remove features, to resolve software bugs
              or to balance the game or adjust the game economy), which will result in mandatory and/or automatic
              updates (older, outdated versions may become unusable over time as a result). As part of these patches,
              updates or changes, we may need to impose limits on, or remove/restrict access to, certain game features
              (without notice or liability). We need these rights in order to keep Wizards running efficiently.
            </p>
            <p>
              <strong> 7. Intellectual Property Rights and Ownership</strong>
            </p>
            <p>
              7.1 “Intellectual Property Rights" means any and all copyright, trademarks, service marks, trade dress,
              brand names, logos, goodwill, get up, trade, business or domain names, design rights, database rights,
              patents, rights in inventions, know-how, trade secrets and confidential information, rights in databases,
              rights in computer software, moral rights, publicity rights, performance rights, synchronization rights,
              mechanical rights, publishing, rental, lending and transmission rights and other intellectual property and
              exploitation rights of a similar or corresponding character which may now or in the future subsist in any
              part of the world, in all cases whether or not registered or registrable including all granted
              applications and all applications for registration, division, continuation, reissuance, renewals,
              extensions, restorations and reversions regarding any of the same.
            </p>
            <p>
              7.2 Wizards ownership. Wizards, including (but not limited to) its visual components, characters, story,
              items, music, graphics, computer code, user interface, look and feel, game mechanics, gameplay, audio,
              video, text, layout, databases, data and all other content and all Intellectual Property Rights (defined
              below) and other legal and exploitation rights regarding them, are either owned by us or we license them
              from third parties. All rights in Wizards are reserved except as we have explained in this Agreement. You
              may not use or exploit any part of Wizards except as explained in this Agreement. Wizards and its
              Intellectual Property Rights are protected by copyright, trademark and other intellectual property laws
              worldwide.
            </p>
            <p>
              7.3 Third party property. GMS respects the intellectual property rights of others. If you believe that
              your work has been infringed in or via Wizards, please contact us via{' '}
              <a href="mailto:info@gamemechs.com">info@gamemechs.com.</a>
            </p>
            <p>
              <strong>8. Wizards Code of Conduct </strong>
            </p>
            <p>
              There are some more rules to follow if you want to play Wizards, including (but not limited to) our Fan
              Content Guidelines. Please read rules below and the Fan Content Guidelines carefully since failure to
              follow them (particularly those in relation to cheating) will be considered a material breach of this
              Agreement, which could lead to cancellation or suspension of your access to Wizards. In particularly
              serious cases we retain the right to prohibit your future access to the game. Here are the rules:{' '}
              <ul>
                <li>Only use Wizards for your personal enjoyment and not for any commercial or political purposes.</li>
                <li>
                  Do not attempt to copy, rent, sell, lend, lease, sublicense, distribute, publish or publicly display
                  Wizards, Virtual Currency, Virtual Goods or your user account or any of your rights under this
                  Agreement to any other party in any way not expressly authorized under this Agreement.
                </li>
                <li>
                  Do not modify, merge, distribute, translate, reverse engineer, or attempt to obtain or use source code
                  of, decompile or disassemble Wizards unless you are specifically allowed by applicable law.
                </li>
                <li>
                  You may not use any unauthorized third party programs, including mods, hacks, cheats, scripts, bots,
                  trainers and automation programs that interact with Wizards in any way, for any purpose, including any
                  unauthorized third-party programs that intercept, emulate, or redirect any communication relating to
                  Wizards and any unauthorized third-party programs that collect info about Wizards or GMS.
                </li>
                <li>
                  Do not share, ‘buy’, ‘sell’, transfer, gift, lend, steal or misappropriate user accounts (which belong
                  to respective platform operators) or Wizards access keys/codes (which are our property). If you are
                  concerned that any of this has happened to you, contact customer support at{' '}
                  <a href="mailto:info@gamemechs.com">info@gamemechs.com.</a>
                </li>
                <li>Do not publicly post any identifying info about yourself, GMS employees, or other players.</li>
                <li>
                  Do not harass, stalk or threaten any other users or employees of GMS. Harassing, stalking or
                  threatening other players or GMS employees;
                </li>
                <li>
                  Do not remove, alter, or conceal any copyright, trademark, patent or other proprietary rights notice
                  of GMS. You also may not transmit content that violates or infringes the rights of others, including
                  patent, trademark, trade secret, copyright, publicity, personal rights or other rights.
                </li>
                <li>
                  You may not avoid, bypass, remove, impair, deactivate or otherwise circumvent any technological
                  measure implemented by GMS or any third-party to protect Wizards.
                </li>
              </ul>
            </p>
            <p>
              <strong>9. Monitoring</strong>
            </p>
            <p>
              In order to prevent cheating and other things prohibited by Section 8 above and to enforce this Agreement,
              we may deploy anti-cheat and other software tools that run in the background of your device or related
              devices/peripherals when you use Wizards.
            </p>
            <p>
              <strong> 10. Virtual Goods and Currency</strong>
            </p>
            <p>
              10.1 How to use Virtual Goods and Virtual Currency. Wizards allows you to purchase virtual, in-game
              digital items and content including but not limited to Wizards gems and other downloadable content (“
              <strong>Virtual Goods</strong>”). You may at our discretion be able to buy certain Virtual Goods with
              “real world” money, or virtual currencies (“<strong>Virtual Currency</strong>”). Such Virtual Goods and
              Virtual Currency may not be traded or sold to other users. Only Wizards users can use Virtual Goods and
              Virtual Currency.
            </p>
            <p>
              10.2 Payments. As your chosen platform’s operator is the entity which provides you with the access to
              Wizards, any content purchased in an in-game store will be purchased from the respective platform operator
              and be subject to its Terms of Services (or other legal documents presented to you by your platform
              operator). If you buy Virtual Goods and/or Virtual Currency from your platform operator, you agree to the
              pricing, payment and billing policies applicable to them, as notified to you at the time of purchase by
              the respective platform. You are responsible for ensuring that you have authorization to use any chosen
              payment method, which includes obtaining account- holder/parent/guardian approval if applicable. You are
              responsible for ensuring that this authorization is maintained at all material times. Do not make
              inappropriate charge-back or refund requests. You are responsible for applicable fees and taxes. All
              payments are non-refundable and non-transferable except as expressly provided in this Agreement.
            </p>
            <p>
              10.3 Legal requirements. Virtual Goods and Virtual Currency are digital items only with no cash-value or
              real-world existence and cannot be ‘bought’, ‘sold’, gifted, transferred or redeemed, whether or not for
              other Virtual Goods, Virtual Currency, ‘real world’ money, goods, services or items of monetary value.
              Trading Virtual Goods or Currency is prohibited (unless we specifically permit otherwise in Wizards). Your
              right to use any Virtual Goods and Virtual Currency that you obtain is limited to a limited,
              non-exclusive, non-assignable, non-transferable, non-sublicensable, revocable license to use such Virtual
              Goods and Virtual Currency solely for your personal entertainment and non- commercial use in Wizards only.
              You have no property interest or right or title in any Virtual Goods or Virtual Currency, which remain
              GMS’s property. GMS reserves the right to reverse, change or amend Virtual Goods or Virtual Currency
              transactions or other matters if necessary to protect Wizards or to enforce this Agreement.
            </p>
            <p>
              10.4 Limits. In order to protect Wizards, Wizards users and to stop fraud, we may impose limits on use of
              Virtual Goods and Virtual Currency (including transaction limits and balance amounts).
            </p>
            <p>
              10.5 Expiration. Virtual Goods and Virtual Currency do not expire, but we reserve the right to change or
              amend that if necessary. We are not obliged to provide Virtual Goods or Virtual Currency to you.
            </p>
            <p>
              10.6 Virtual Goods/Currency may change. The existence of a particular offer for Virtual Goods or Virtual
              Currency is not a commitment by us to maintain or continue to make the Virtual Goods or Virtual Currency
              or that offer available in the future. The scope, variety and type of Virtual Goods and Virtual Currency
              that you may obtain can change at any time and we have the right to manage, regulate, control, modify or
              remove Virtual Currency or Virtual Goods in our sole discretion if we consider any of this necessary for
              the ongoing operation of Wizards or for other legitimate reasons, in which case we will have no liability
              to you or anyone for the exercise of such rights. We will endeavor where possible to give you reasonable
              notice of any such changes and to explain the reasons why.
            </p>
            <p>10.7 Refund rights.</p>
            <p> If you are resident in the European Union (EU):</p>
            <p>
              Subject to the terms of any applicable device/platform via which you access Wizards: you have the right to
              withdraw from a purchase of Wizards, Virtual Currency and/or Virtual Goods within 14 days of your
              purchase, without giving a reason. You hereby expressly acknowledge that you lose your right of withdrawal
              once the performance of our service has begun and your account is provided with access to the Virtual
              Goods and/or Virtual Currency. You agree that the supply of Virtual Goods and/or Virtual Currency and the
              performance of services begins immediately after you complete your purchase. Therefore, once access to the
              Virtual Goods and/or Virtual Currency has been enabled on your account, the contract has been fully
              performed by us.
            </p>
            <p> If you are resident elsewhere in the world outside the European Union (including the USA):</p>
            <p>
              Subject to the terms of any applicable device/platform via which you access Wizards: all purchases are
              final and no refunds will be made or returns accepted.
            </p>
            <p>
              <strong> 11. Third Party Content and External Services</strong>
            </p>
            <p>
              You might get links from us to third party websites or content through Wizards. You may also access
              Wizards through different platforms and devices. Using them is up to you – we cannot promise they will
              work, what they will be like or if they are free. Our partner devices/platforms may be subject to their
              own legal terms, compliance with which is your sole responsibility. They may also offer complementary
              features to Wizards (such as digital money wallets) – again, this is a matter solely for you and them.
            </p>
            <p>
              <strong> 12. Warranties and Liability</strong>
            </p>
            <p>
              12.1 Our warranties. We warrant that: (i) we have the right to enter into this Agreement and to grant you
              the license to use Wizards in section 3; (ii) we will take reasonable care with Wizards and your use of it
              and (iii) we will use reasonable endeavors to comply with applicable laws in performing our obligations to
              you under this Agreement.
            </p>
            <p>
              12.2 Your representations and warranties. You represent and warrant that you have the full power and
              ability to enter into this License Agreement and will follow fully its terms. You also represent and
              warrant that any Shared User Content, which you transmit via Wizards does not infringe upon the
              intellectual property rights of any third party. You further represent and warrant that you will not use
              or contribute Shared User Content that is unlawful, tortious, defamatory, obscene, invasive of the privacy
              of another person, threatening, harassing, abusive, hateful or racist.
            </p>
            <p>
              <strong> 13. Limitation of liability</strong>
            </p>
            <p>
              This section does not apply to you if you are resident in the European Union or countries whose laws
              specifically prohibit the following liability limitations, but it does apply to you if you are resident in
              the USA.
            </p>
            <p>
              (i) OUR DISCLAIMERS. Except as we have set out elsewhere in this Agreement, GMS and its affiliates,
              partners and licensors disclaim any implied or express warranties or representations regarding Wizards.
              This includes without limitation any allegations of:
            </p>
            <p> (1) negligence; or</p>
            <p>(2) lack of satisfactory quality, merchantability or fitness for purpose; or </p>
            <p> (3) the existence of any faults or errors; or </p>
            <p>
              (4) infringement of any third-party intellectual property rights. Wizards is otherwise provided to you on
              an "as is", “as available” basis without warranties or representations of any kind, express or implied. To
              the fullest extent permitted by applicable law, we disclaim all warranties, express or implied, which
              might apply to Wizards, including without limitation: implied warranties of title, non-infringement,
              merchantability, satisfactory quality, fitness for a particular purpose, any warranties that may arise
              from course of dealing or course of performance or usage of trade, freedom from viruses or errors or
              defects, and/or any warranties as to the accuracy, legality, reliability or quality of any content or
              information contained within Wizards. We do not warrant that Wizards will be uninterrupted or error-free,
              that defects will be corrected, or that the game will be free of viruses or other harmful components.
            </p>
            <p>
              (II) OUR LIMITATION OF LIABILITY. To the maximum extent permitted by applicable law, GMS, its affiliates,
              partners and licensors shall not be liable for any loss, damage or harm of any kind arising from the use
              or inability to use or ‘loss’ relating to Wizards. GMS, its affiliates, partners and licensors shall not
              be liable for any indirect, consequential, incidental, special, punitive or exemplary damages or any other
              damages arising out of or connected with the same. This includes if you suffer damage because you cannot
              use Wizards (either temporarily or permanently). None of the above will be affected in any way even if GMS
              or its affiliates, partners or licensors are at fault (whether through negligence, breach of contract,
              breach of warranty or strict liability) and even if you or we have been advised of the possibility of such
              damages.
            </p>
            <p>
              (III) OUR LIABILITY CAP. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL OUR TOTAL
              LIABILITY TO YOU IN CONNECTION WITH GWENT OR THIS AGREEMENT EXCEED AN AMOUNT EQUAL TO THE AMOUNT YOU HAVE
              ACTUALLY PAID US (IF ANY) IN CONNECTION WITH THE MATTERS UNDERLYING ANY CLAIM(S).
            </p>
            <p>
              (IV) YOUR INDEMNITY TO US. You agree to indemnify and hold harmless on demand GMS, its affiliates,
              licensors and partners from all liabilities, claims and expenses, including legal fees, in connection
              with:
            </p>
            <p> (1) any alleged or actual breach of this Agreement; </p>
            <p>(2) the use of Wizards by you or any person on your behalf;</p>
            <p>(3) Infringement of intellectual property rights or other property of GMS; and/or </p>
            <p>
              (4) YOUR SHARED USER CONTENT. If claims are brought against us, then you will cooperate with us and we
              reserve the right to take over their defense. You will not settle any such claims without our prior
              written consent.
            </p>
            <p>
              <strong> 14. Termination </strong>
            </p>
            <p>
              14.1 Your termination rights. You can terminate this Agreement by permanently stopping use of Wizards at
              any time. Termination will not affect already existing rights or obligations of us or you.
            </p>
            <p>
              14.2 Our termination rights. We may cancel or suspend your access to Wizards if you materially breach this
              Agreement, which includes but is not limited to a breach of this Agreement which is serious and/or which
              could cause real harm to Wizards, Wizards users, GMS or other matters governed under this Agreement. In
              particular, it applies to the Wizards rules we specify in section 8 above (for example, no cheating or
              account misuse). If we cancel or suspend your account in this way, we will try reasonably to contact you
              to explain why we have done this and what (if anything) you can do as a result. Cancellation or suspension
              will include you losing access to Virtual Goods and Virtual Currency. If we cancel or suspend your account
              under this section, then we will not have any obligations or liabilities to you at all.
            </p>
            <p>
              14.3 Sunsetting Wizards. If we have to stop providing access to Wizards permanently (not because of any
              breach by you), we will attempt to provide you at least one hundred and twenty (120) days advance notice
              by posting a note on <a href="http://gamemechs.com/">http://gamemechs.com/</a>. In this case, we will not
              have any future obligations or liabilities to you (this does not affect any pre-existing obligations or
              liabilities).
            </p>
            <p>
              <strong>15. INUNCTIVE RELIEF</strong>
            </p>
            <p>
              (V) INJUNCTIVE RELIEF. YOU AGREE THAT ANY LOSS, DAMAGE OR HARM YOU SUFFER ARE NOT IRREPARABLE OR
              SUFFICIENT, and other remedies will be adequate, such that you are not entitled TO INJUNCTIVE OR OTHER
              EQUITABLE RELIEF AGAINST US.
            </p>
            <p>
              (VI) Residents of California. If you reside in the state of California you are entitled to the following
              specific consumer rights information: you may contact the Complaint Assistance Unit of the Division of
              Consumer Services of the Department of Consumer Affairs by mail at 400 R St., Suite 1080, Sacramento,
              California, 95814, or by telephone at 916.445.1254. California residents expressly agree to waive
              California Civil Code Sec. 1542, which states: “A general release does not extend the claims which the
              creditor does not know or suspect to exist in his favor at the time of executing the release, which if
              known by him must have materially affected his settlement with the debtor.”
            </p>
            <p>
              <strong> 16. Force Majeure</strong>
            </p>
            <p>
              Neither of us will be liable to the other regarding any performance, or non-performance, or delay, in
              whole or in part, due to Force Majeure.
            </p>
            <p>
              “Force Majeure” means any cause preventing a party from performing any or all of its obligations which
              arises from or is attributable to acts, events, omissions or accidents beyond the reasonable control of
              the party so prevented including strikes, lock-outs or other industrial disputes (other than any such
              dispute involving the workforce of the party so prevented), nuclear accident or acts of God, war or
              terrorist activity, riot, civil commotion, malicious damage (excluding malicious damage involving the
              employees of the affected party or its sub-contractors), compliance with any law or governmental order,
              rule, regulation or direction, industrial action by employees of any providers of electrical power,
              failure of technical facilities, hacking, denial of service or other IT attack, deployment of IT virus
              malware or similar technology, fire, flood, or storm or default of suppliers or sub-contractors.
            </p>
            <p>
              <strong>17. Governing Law </strong>
            </p>
            <p>
              To the extent not covered by the Dispute Resolution and Arbitration language below, you and we agree that
              your use of Wizards, and this Agreement, and any issues arising out of them, will be deemed to be entered
              into in Los Angeles, California and governed by and interpreted according to the laws of the State of
              California, USA (and, if applicable, US Federal law) without regard to choice of law principles. Any legal
              claim by you against GMS, to the extent not covered by the Dispute Resolution and Arbitration language
              below, will be made exclusively in state or federal court located in Los Angeles, California, which will
              have subject matter jurisdiction regarding the dispute between you and us and therefore we both consent to
              the exclusive jurisdiction of those courts. Moreover, you waive any rights to argue that the state and
              federal courts in Los Angeles, California are an improper venue. In any legal claim under this Agreement,
              the side who wins will be entitled to its legal fees and expenses.
            </p>
            <p>
              Any legal questions / complaints / claims regarding this Agreement are under Polish law and jurisdiction
              for users all around the world except users who reside in the USA, who are under California law and
              jurisdiction.
            </p>
            <p>
              <strong>18. DISPUTE RESOLUTION AND BINDING ARBITRATION </strong>
            </p>
            <p>
              If you have concerns or issues with us, we hope we can resolve them quickly and amicably through Wizards
              support service accessible at <a href="mailto:info@gamemechs.com">info@gamemechs.com</a>. However, we
              recognize that occasionally there might be legal disputes which are not so easily resolved. In this
              section we explain what happens if there is a legal dispute.
            </p>
            <p>
              (1) Dispute resolution next steps: if you live in the European Union or elsewhere in the world (but not
              the USA):
            </p>
            <p>
              You and we have the legal right to commence legal claims against each other if we consider it necessary.
              If you bring a claim against GMS, you should address it to:
            </p>
            <p> Legal Team,</p>
            <p>
              [5673 Whitnall Highway, North Hollywood, CA 91601] with a copy to{' '}
              <a href="mailto:info@gamemechs.com">info@gamemechs.com</a>.
            </p>
            <p>(2) Dispute resolution next steps: if you live in the USA, Arbitration:</p>
            <p>
              We and you agree to resolve all disputes and claims between us in individual binding arbitration. This
              includes without limitation any claims arising from this Agreement, any part of the relationship between
              you and us or your use of Wizards or other GMS games and services. This section applies whether the
              dispute or claim is based in contract, tort, statute, fraud, unfair competition, misrepresentation or any
              other legal doctrine.
            </p>
            <p>
              (Some explanatory notes from us: "arbitration" is a consensual dispute resolution process where both sides
              present their case to a neutral arbitrator (not a judge or jury). Arbitration is less formal than court
              litigation and it has less formal rules (which we talk about below). Just so we and you are clear: by
              choosing arbitration you and we are giving up the right to have any dispute between us heard in court
              before a judge and/or jury.) You and GMS agree that any claim arising out of or related to Wizards must be
              made within one (1) year after the claim arose; otherwise, such claim is permanently barred. You agree
              that the provisions in this paragraph will survive any termination of your account or Wizards.
            </p>
            <p> How to start an arbitration:</p>
            <p>
              If either of us wants to commence arbitration, then they have to send the other side a written notice
              setting out the basis of the claim and what remedy the commencing side wants from the other side. A
              printed version of this Agreement and of any notice given in electronic form shall be admissible to the
              same extent and subject to the same conditions as other business documents and records originally
              generated and maintained in printed form. If you send a notice to us, please send by mail and by email.
              You or GMS may bring an arbitration at any reasonable AAA location within the United States that is
              convenient for you. The rules for the arbitration: The US Federal Arbitration Act applies to this section.
              The arbitration will be governed by the Commercial Arbitration Rules of the American Arbitration
              Association (“
              <strong>AAA</strong>”) and, where applicable, the AAA’s Supplementary Procedures for Consumer Related
              Disputes, as modified by this Agreement (http://www.adr.org). The arbitrator will be bound by this
              Agreement.
            </p>
            <p>
              The arbitration shall be conducted by a single arbitrator with substantial experience in resolving
              intellectual property and commercial contract disputes, who shall be selected from the appropriate list of
              AAA arbitrators. The determination of whether a dispute is subject to arbitration shall be governed by the
              Federal Arbitration Act and determined by an arbitrator rather than a court. The AAA will administer the
              arbitration and it will be conducted in the English language. It may be conducted through the submission
              of documents, by phone, or in person at a mutually agreed location. The arbitration hearing must commence
              within thirty (30) days from the appointment of the arbitrator, unless otherwise agreed to by you and GMS.
              The AAA will only have power to arbitrate the dispute between you and us and not in relation to other
              people under this particular arbitration. Judgment upon an award rendered by the arbitrator may be entered
              in any court having jurisdiction or application may be made to such court for judicial acceptance of any
              award and an order of enforcement, as the case may be.
            </p>
            <p>
              If you seek US $1,000 or less, GMS agrees to reimburse your filing fee and your share of the arbitration
              costs (but not including any attorney’s fees or expert witness fees), including your share of arbitrator
              compensation, at the end of the arbitration, unless the arbitrator decides your claims are without merit
              or your costs are unreasonable. GMS agrees not to seek its legal fees or costs in the arbitration unless
              the arbitrator determines your claims are without merit or your costs are unreasonable. If you seek more
              than US $1,000 then the arbitration costs, including arbitrator compensation, will be split between you
              and GMS according to the AAA Commercial Arbitration Rules and the AAA’s Supplementary Procedures for
              Consumer Related Disputes, if applicable.
            </p>
            <p>What the dispute resolution and arbitration sections do not apply to:</p>
            <p>
              The dispute resolution and arbitration requirements do not apply to claims or disputes brought by either
              you or us which relate to claims of intellectual property rights infringement or claims of unauthorized
              use, piracy, theft or misappropriation.
            </p>
            <p>
              This section does not prevent you from bringing your dispute to the attention of any federal, state, or
              local government agencies that can, if the law allows, seek relief from us for you.
            </p>
            <p>Waiver of collective action remedies:</p>
            <p>
              To the maximum extent permitted by the national or state law applicable, you and we agree not under any
              circumstances to bring or participate in a class or representative action, private attorney general action
              or collective arbitration. That means, to the full extent permitted by law, (1) no arbitration shall be
              joined with any other; (2) there is no right or authority for any dispute to be arbitrated on a
              class-action basis or to utilize class action procedures; and (3) there is no right or authority for any
              dispute to be brought in a purported representative capacity on behalf of the general public or any other
              persons.
            </p>
            <p>
              If the agreement in this section not to bring or participate in a class or representative action, private
              attorney general action or collective arbitration is found illegal or unenforceable, you and GMS agree
              that it will not be severable: this entire section will be deemed unenforceable and any claim or dispute
              will therefore be resolved in court.
            </p>
            <p>
              If you have any concerns or issues you can contact Wizards support. We hope we can resolve any complaints
              with you through informal dispute resolution.
            </p>
            <p>If we can't resolve a dispute with you informally, then this is what happens next.</p>
            <p>
              <strong> IMPORTANT:</strong> if you live in the USA, this section involves both you and us agreeing to
              mandatory arbitration of any dispute between us.
            </p>
            <p>
              If you live in the USA or the rest of the world (but not the EU) you and we agree not to bring any class
              action or similar collective legal action against each other. We will resolve legal disputes with each
              other through the process outlined above.
            </p>
            <p>
              <strong>19. Miscellaneous.</strong>
            </p>
            <p>
              19.1 If any part of this Agreement is found not to be legally enforceable, this will not affect any other
              part of it. This Agreement governs our relationship with you (and vice versa). It does not create any
              rights for anyone else. Please remember that we are subject to various laws and we may be required to
              comply with law enforcement or other legal requirements, including import/export controls.
            </p>
            <p>
              19.2 You and we agree that the UN Convention on Contracts for the International Sale of Goods does not
              apply to Wizards or this Agreement.
            </p>
            <p>
              19.3 We can assign, subcontract or transfer this Agreement to a third party or another member of our group
              if necessary, for the support of Wizards, as part of any reorganization or merger or for other business
              reasons. We will notify you if this happens.
            </p>
            <p>
              19.4 No failure or delay by us or you to exercise any right or remedy provided under this Agreement or by
              law will constitute a waiver of that or any other right or remedy, nor will it preclude or restrict the
              further exercise of that or any other right or remedy. No single or partial exercise of such right or
              remedy by us or you will preclude or restrict the further exercise of that or any other right or remedy.
            </p>
            <p>
              <strong> 20. Changes and Updates to this Agreement</strong>
            </p>
            <p>
              20.1 We may change this Agreement if we think it is necessary, e.g. for legal reasons or to reflect
              changes in Wizards. If so, we will make the changed Agreement available online and make reasonable efforts
              to tell you about it (by e.g. sending you a notice in the game and asking you to accept the change).
            </p>
            <p>
              20.2 Once we change the Agreement, it will become legally binding on you 30 days after we post it online.
              During that period, you're welcome to contact us at{' '}
              <a href="mailto:info@gamemechs.com">info@gamemechs.com</a> if you have specific questions about the
              changes.
            </p>
            <p>
              20.3 If you do not agree to those changes (regardless of whether you email us), then unfortunately we need
              to ask you to cease using Wizards. We are sorry we have to say that, but we hope you will appreciate that
              for Wizards to work properly we need to have everyone using them under the same rules instead of different
              people having different rules. That's why we encourage you to get in contact if you have queries or
              concerns.
            </p>
          </TermsText>
        </Container>
      </CenterH>
    </div>
  )
}

export default TermsOfService
