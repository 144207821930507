import React from 'react'

import styled from 'styled-components'

const Frame = styled.div`
  margin: 24px;
  color: white;
`

const Poster = styled.div`
  height: 540px;
  width: 360px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
`

const ComingSoon = styled.div`
  margin-top: 100px;
  position: relative;
  font-weight: 700;
  width: 220px;
`

const Title = styled.div`
  font-size: 30px;
  padding-top: 100px;
  @media screen and (max-width: 1090px) {
    font-size: 20px;
  }
  @media screen and (max-width: 900px) {
    padding-top: 50px;
  }
  @media screen and (max-width: 700px) {
    padding-top: 0px;
  }
`

const SummaryWrap = styled.div`
  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (min-width: 655px) {
    font-size: 20px;
  }
`

const Buttons = styled.div`
  padding: 30px 0px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding-left: 20px;
  @media screen and (max-width: 1600px) {
    width: 400px;
  }
`

const Button = styled.img`
  width: 80px;
`

interface Props {
  extendedSummary: string
  platforms: Array<string>
  comingSoon: boolean
  plainPosterImage: string
  bannerImage: string
  thumbnails: string
  videoUrl: string
  releaseDate: string
}

const AboutTheGame = ({
  extendedSummary,
  platforms,
  comingSoon,
  plainPosterImage,
  bannerImage,
  thumbnails,
  videoUrl,
  releaseDate,
}: Props) => {
  return (
    <>
      <img src={bannerImage} width="100%" height="100%" alt="banner" />
      <Frame>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Poster>
            <img width="100%" height="100%" src={plainPosterImage} alt="poster" />
          </Poster>
          <Container>
            <Title>
              About the game <br />
              <br />
            </Title>
            <SummaryWrap>{extendedSummary}</SummaryWrap>
            <ComingSoon>{comingSoon ? `COMING ${releaseDate}` : 'NOW AVAILABLE'}</ComingSoon>
            <Buttons>
              {platforms.map((platform: any, i: number) => {
                if (platform.availableNow === true) {
                  return (
                    <Button
                      style={{ paddingRight: 15, paddingBottom: 15 }}
                      key={i}
                      src={platform.logoImage}
                      alt={platform.platform}
                    />
                  )
                } else return null
              })}
            </Buttons>
          </Container>
        </div>
      </Frame>
    </>
  )
}

export default AboutTheGame
