import React, { createContext, FunctionComponent, useState } from 'react'
import Client from 'shopify-buy'

export const ShopifyContext = createContext({})

interface IProps {}

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
  domain: 'union25.myshopify.com',
  storefrontAccessToken: '190f77e427537e5a1ada4c9b2cf72c93',
})

const ShopifyProvider: FunctionComponent<IProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState(Array())
  const [cartId, setCartId] = useState('')
  const [count, setCount] = useState(0)

  const state = {
    getProducts: async () => {
      return await client.product.fetchAll().then((products) => {
        return products
      })
    },
    getProduct: async (productId: string) => {
      return await client.product.fetch(productId).then((product) => {
        // Do something with the product
        console.log(product)
        return product
      })
    },
    createCheckout: async () => {
      return await client.checkout.create().then((checkout) => {
        // Do something with the checkout
        console.log(checkout)
        return checkout
      })
    },
    addProductToCheckout: (checkoutId: string, lineItemsToAdd: any) => {
      client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
        // Do something with the updated checkout
        console.log(checkout)
      })
    },
    updateProductInCheckout: (checkoutId: string, lineItemsToUpdate: any) => {
      // update quantity
      client.checkout.updateLineItem(checkoutId, lineItemsToUpdate).then((checkout) => {
        // Do something with the updated checkout
        console.log(checkout.lineItems)
      })
    },
    deleteProductInCheckout: (checkoutId: string, lineItemsToRemove: any) => {
      // Remove an item from the checkout
      client.checkout.removeLineItems(checkoutId, lineItemsToRemove).then((checkout) => {
        // Do something with the updated checkout
        console.log(checkout.lineItems)
      })
    },
    getCheckout: (checkoutId: string) => {
      // Get the checkout
      client.checkout.fetch(checkoutId).then((checkout) => {
        // Do something with the  checkout
        console.log(checkout)
      })
    },
    getCheckoutLength: async (checkoutId: string) => {
      // Get the checkout
      return await client.checkout.fetch(checkoutId).then((checkout) => {
        // Get the amount of items in cart
        return checkout.lineItemCount
      })
    },
    cartItems: cartItems,
    cartId: cartId,
    count: count,
    setCount,
    setCartItems,
    setCartId,
  }

  return <ShopifyContext.Provider value={state}>{children}</ShopifyContext.Provider>
}

export default ShopifyProvider
