import React, { useContext } from 'react'
import styled from 'styled-components'
import { Center, CenterH, Banner } from '../components/layout'
import { Link } from 'react-router-dom'

import { AssetContext } from '../contexts/assetContext'

const Container = styled.div`
  background-color: white;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
`

const PrivacyText = styled.div`
  color: black;
  width: 90%;
`

const PrivacyPolicy = () => {
  const assets: any = useContext(AssetContext)
  const { privacyPolicy }: any = assets

  return (
    <div>
      <Banner>
        <Center>PRIVACY POLICY</Center>
      </Banner>
      <CenterH>
        <Container>
          <PrivacyText>
            <Center>
              <p>
                <strong>Game Mechanic Studios</strong>
              </p>
              <p>
                <strong>Wizards Wand of Epicosity Privacy Policy</strong>
              </p>
            </Center>
            <p>
              This privacy and data policy (“<strong>Privacy Policy</strong>”) applies and has effect in respect to all
              versions of our game entitled, Wizard Wands of Epicosity (“<strong>Wizards</strong>”).
            </p>
            <p>
              <strong>1. Introduction</strong>.
            </p>
            <p>
              We are committed to protecting and respecting your privacy. The Privacy Policy describes the basis on
              which any data and information we collect from you will be processed by us or on our behalf.
            </p>
            <p>
              Please read this Privacy Policy carefully as it contains important information about the following:
              <ul>
                <li>What types of information we may collect about you;</li>
                <li>How we will use information we collect about you;</li>
                <li>Whether we will disclose your details to anyone else; and </li>
                <li>Your choices and rights regarding the personal information you have provided to us.</li>
              </ul>
            </p>
            <p>
              This Privacy Policy forms a part of and should be read in conjunction with our Wizards{' '}
              <Link to="/termsofservice">End User License Agreement</Link>. Where we particular personal data, those
              lists should not be considered complete, but an indication of the categories of personal data we process.
            </p>
            <p>
              Wizards or services provided on the platform (e.g. PS4, Steam, XboxOne) you play Wizards on may contain
              hyperlinks to services owned and operated by third-parties. These third-party services may have their own
              privacy policies and we recommend that you review them. They will govern the use of personal information
              that you submit or which is collected by cookies and other tracking technologies whilst using these
              services. We do not accept any responsibility or liability for the privacy practices of such third-party
              services and your use of these is at your own risk.
            </p>
            <p>
              We may make changes to this Privacy Policy in future, which will be posted on this page. You should check
              this page from time to time to ensure you are aware of any changes. Where appropriate we may notify you of
              changes through the Wizards game.
            </p>
            <p>
              <strong> 2. Information we Collect about You</strong>
            </p>
            <p>
              Normally, when you play Wizards, we do not seek / collect your name, address, email, credit card or other
              personal information about you. There are however instances, such as when you request support, when we may
              need to collect your personal information.
            </p>
            <p>We collect the following information from you when you play Wizards:</p>
            <p>
              Gameplay Information:
              <ul>
                <li>your account identifier, username (or gamertag);</li> <li> player ID;</li>
                <li>your console’s unique device ID;</li>
                <li>
                  your platform player ID, as may be allocated to you by third party platforms such as PlayStation,
                  Steam or Xbox;
                </li>
                <li>any third-party platform profile display names</li>
                <li>your user preferences, including your language and country; and</li>
                <li>
                  core milestones related to your gameplay, such as your overall progression to a particular level or
                  stage, or the completion of certain activities during your gameplay (for example, completing a
                  tutorial or passing a level).
                </li>
                <li>
                  Information provided by you when you contact us or use any help center or similar service through us
                  or a platform.
                </li>
              </ul>
            </p>
            <p>Support Information:</p>
            <p> We may ask you for, or you may submit, certain information to us whenever you.</p>
            <p>
              This may include:
              <ul>
                <li>your name;</li>
                <li>your email;</li> <li> location data specifying the city in which you are located; </li>
                <li> the type of device you’re using to play a Game;</li>
                <li>
                  your platform player ID, as may be allocated to you by third party platforms such as PlayStation or
                  Steam; and
                </li>
                <li>
                  debug logs, which are records of technical information relating to software operation and errors that
                  may have occurred during that operation.
                </li>
                <li> Information provided by you when using the Forums</li>
              </ul>
            </p>
            <p>
              Analytics:
              <ul>
                <li>
                  We may collect technical information about your use of the Online Services through the use of tracking
                  technologies and analytics.
                </li>
                <li>Personal data we may collect includes the following:</li>
                <li>your IP and MAC address; </li>
                <li>your internet service provider;</li>
                <li>your Device ID;</li>
                <li>your Console ID;</li>
                <li>your user ID (which is generated by us and allocated to you when you first Wizards); </li>
                <li>your device operating system & version;</li>
                <li>your device make and model;</li>
                <li>game play attempts, progression and results; </li>
                <li>session game time start, end and duration;</li> <li> the country of your device; and</li>
                <li>identification of crashes and defects.</li>
              </ul>
            </p>
            <p>We may use as data provided by third-party platforms such as PlayStation and Steam.</p>
            <p>
              Steam may collect analytics data on our behalf and in accordance with our instructions, and their
              applicable privacy notice. If you would like to find out more about the way Steam collects and process
              this information this is likely to be set out in their privacy policies and/or terms and conditions.
            </p>
            <p>
              Please refer to{' '}
              <a href="https://store.steampowered.com/privacy_agreement/">
                https://store.steampowered.com/privacy_agreement/
              </a>
              .
            </p>
            <p>
              We also obtain data in respect of software crashes from Microsoft and Sony. This includes technical
              information about your device, the software and applications running on your device and your user ID.
            </p>
            <p>
              <strong>3. Why We Collect Information About You</strong>
            </p>
            <p>
              We will use information about you for delivering Wizards to you under the terms of use agreed between us.
              The processing of information in this way is necessary for us to record your progress and current status
              within Wizards, to help you have a great experience when playing.
            </p>
            <p>We also use information to help us fix problems.</p>
            <p>
              We may also use your information, including personal information to respond to your enquiries and requests
              for support.
            </p>
            <p>
              <strong> 4. Data Sharing</strong>
            </p>
            <p> WE DO NOT SELL YOUR DATA TO ANY THIRD-PARTIES.</p>
            <p>
              We will share your information with third parties only in the ways that are described in this Privacy
              Policy.
            </p>
            <p>
              We do not store any information you to provide to us on any server, except that we may store information
              that you provide to us in the course of providing support or through correspondence between us.
            </p>
            <p>
              As such, we ask that if you do contact us, you provide us with as little personal information as possible.
            </p>
            <p>
              We may disclose your information to the extent that we are required to do so by law (which may include to
              government bodies and law enforcement agencies); in connection with any legal proceedings or prospective
              legal proceedings; and in order to establish, exercise or defend our legal rights (including providing
              information to others for the purposes of fraud prevention).
            </p>
            <p>
              We may also disclose your personal information to third-parties in order to enforce or apply the terms of
              agreements, to investigate potential breaches, or to protect the rights, property or safety of GMS, our
              customers, or others.
            </p>
            <p>
              Where Wizards downloaded through Xbox Live, Steam and/or PSN, we may disclose the information we collect
              from you to Valve Corporation, Microsoft, Sony, respectively for the purposes of facilitating any payments
              made through these platforms.
            </p>
            <p>
              <strong>5. Consumer Control and Opt-Out Options</strong>
            </p>
            <p>
              If you would like to exercise any of your rights in respect of your personal data, please contact us at{' '}
              <a href="mailto:info@gamemechs.com">info@gamemechs.com</a>.
            </p>
            <p>
              <strong>6. Children</strong>
            </p>
            <p>
              We do not use Wizards to knowingly solicit information from or market to children under the age of 13. Our
              terms of use prohibit users aged under 13 years from accessing Wizards. In the event that we learn that we
              have collected personal information from a child under 13 years of age we will delete that information as
              quickly as possible.
            </p>
            <p>
              <strong>7. Security</strong>
            </p>
            <p>
              We will take all reasonable technical and organizational precautions to prevent the loss misuse or
              alteration of your personal information. Please be aware that, although we attempt to provide reasonable
              security for information we process and maintain, no security system can prevent all potential security
              breaches.
            </p>
            <p>
              <strong> 8. Contact Information</strong>
            </p>
            <p>
              All other questions, comments or enquiries should be directed to GMS at [info@gamemechs.com]. We will
              attempt to respond to any query or questions within a timely manner.
            </p>
          </PrivacyText>
        </Container>
      </CenterH>
    </div>
  )
}

export default PrivacyPolicy
