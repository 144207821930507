import React, { useContext } from 'react'
import { CenterH, Banner } from '../components/layout'

import { AssetContext } from '../contexts/assetContext'
import Footer from '../components/footer'

const Teamwork = () => {
  const assets: any = useContext(AssetContext)
  const { teamWork }: any = assets

  return (
    <div>
      <Banner>
        <CenterH>TEAMWORK ELEVATES US ALL</CenterH>
      </Banner>
      <CenterH style={{ flexWrap: 'wrap', paddingTop: 25 }}>
        {teamWork.map((game: any, index: number) => {
          return (
            <img key={index} src={game.image} alt={'game' + game.id} height={230} width={410} style={{ padding: 5 }} />
          )
        })}
      </CenterH>
      <Footer />
    </div>
  )
}

export default Teamwork
