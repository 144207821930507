import React, { useContext, useEffect, useState } from 'react'
import './thumbModal.css'

import styled from 'styled-components'
import { CenterH } from '../components/layout'
import { ShopifyContext } from '../contexts/shopifyContext'

const InfoArea = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
`

const ImagesArea = styled.div`
  display: flex;
  flex-direction: column;
`

const BoxImage = styled.div`
  width: 250px;
  height: 250px;
  background-color: grey;
  margin: 20px;
`

const ThumbnailsArea = styled.div`
  width: 800px;
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Thumbnail = styled.div`
  width: 140px;
  background-color: grey;
  margin: 10px;
  cursor: zoom-in;
  transition: opacity 0.4s;
`

const TextArea = styled.div`
  width: 50vw;
  margin: 20px;
  color: white;
`

const TitleText = styled.div`
  margin: 10px;
  font-size: 30px;
  font-weight: 400;
`
const PriceText = styled.div`
  margin: 10px;
  font-size: 20px;
  font-weight: 400;
`

const Button = styled.button`
  background-color: white;
  color: red;
  border: 1px solid red;
  padding: 12px;
  margin-left: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  width: 100px;
  cursor: pointer;
  transition: transform 0.25s;

  &:active {
    transform: scale(0.75);
  }
`

const VariantButton = styled.button`
  margin: 10px;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.25s;
  &:active {
    transform: scale(0.75);
  }
`

const DescriptionArea = styled.div`
  background-color: darkgray;
  height: 150px;
  padding: 15px;
  width: 100%;
`

const Quantity = styled.input`
  width: 80px;
  height: 35px;
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  padding-left: 10px;
  font-weight: bold;
`

interface Props {
  height: number
  width: number
  product: any
  variants: Array<any>
}

const StoreDetails = ({ height, width, product, variants }: Props) => {
  const toggleSize = (id: string) => {
    const modal = document.querySelector('.thumbModal')
    if (id !== '') {
      const img = document.getElementById(`${id}`) as HTMLImageElement
      const modalImage = document.getElementById('modalImage')
      if (modalImage) {
        modalImage.setAttribute('src', img.src)
      }
    }
    modal?.classList.toggle('active')
  }

  ////// none-number characters not allowed
  const inputBox = document.getElementById('amountInput')
  const invalidChars = ['-', '+', 'e']
  inputBox?.addEventListener('keydown', function (e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  })
  //////

  const [variantPrice, setVariantPrice] = useState(variants[0].price)
  const [variantInStock, setVariantInStock] = useState(variants[0].available)
  const [variantId, setVariantId] = useState(variants[0].id)
  const [amount, setAmount] = useState(0)
  const assets: any = useContext(ShopifyContext)
  const { setCartItems, cartItems, setCount, count }: any = assets

  useEffect(() => {
    if (!variantPrice) {
      setVariantPrice(variants[0].price)
      setVariantInStock(variants[0].available)
      setVariantId(variants[0].id)
    }
  })

  function changeVariant(id: string, price: string, available: boolean) {
    setVariantPrice(price)
    setVariantInStock(available)
    setVariantId(id)
    setAmount(0)
  }

  useEffect(() => {
    async function amount() {
      cartItems.map((item: any) => (item.variantId === variantId ? setAmount(item.quantity) : null))
    }
    amount()
  }, [variantId])

  useEffect(() => {
    let counter = 0
    cartItems.forEach((item: any) => {
      counter += item.quantity
    })
    setCount(counter)
    setCartItems(cartItems)
  }, [cartItems])

  function addToCart() {
    const lineItemsToAdd = [
      {
        variantId: variantId,
        quantity: +amount,
      },
    ]

    if (cartItems.filter((e: any) => e.variantId === variantId).length > 0) {
      /* items already contains the element we're looking for */
      if (amount > 0) {
        setCartItems(
          cartItems.map((item: any) => (item.variantId === variantId ? { ...item, quantity: +amount } : item))
        )
      } else {
        /* items quantity is reduced to 0 */
        setCartItems(cartItems.map((item: any) => (item.variantId === variantId ? { ...item, quantity: 0 } : item)))
      }
    } else {
      setCartItems(cartItems.concat(lineItemsToAdd))
    }
  }

  return (
    <>
      <CenterH>
        <InfoArea>
          <ImagesArea>
            <BoxImage>
              <img height="100%" width="100%" src={product.images ? product.images[0].src : ''} alt="squarePoster" />
            </BoxImage>
          </ImagesArea>
          <TextArea>
            <TitleText>{product.title} </TitleText>
            <div>
              {variants.map((variant: any, i: number) => {
                return (
                  <VariantButton
                    style={{
                      backgroundColor: variant.id === variantId ? 'grey' : 'black',
                    }}
                    key={i}
                    autoFocus={variantId === variant.id ? true : false}
                    onClick={() => changeVariant(variant.id, variant.price, variant.available)}>
                    <img
                      style={{ height: '100%', width: '100%' }}
                      src={variant.image ? variant.image.src : ''}
                      alt={variant.title}
                    />
                  </VariantButton>
                )
              })}
            </div>
            <br />
            <PriceText>${variantPrice}</PriceText>
            <PriceText style={{ color: variantInStock ? 'green' : 'red' }}>
              {variantInStock ? 'In Stock' : 'Unavailable'}
              <br />
              <div>
                <Quantity
                  disabled={!variantInStock}
                  type="number"
                  id="amountInput"
                  name="amount"
                  value={amount}
                  min={0}
                  onChange={(e: any) => setAmount(e.target.value)}
                />
                {variantInStock ? <Button onClick={() => addToCart()}>Update Cart </Button> : null}
              </div>
            </PriceText>
          </TextArea>
          <ThumbnailsArea>
            {product.images
              ? product.images.slice(1, product.images.length).map((thumbnail: any, i: number) => {
                  if (thumbnail.src.match(/screenshot/i)) {
                    return (
                      <Thumbnail key={i} onClick={() => toggleSize('img' + i)}>
                        <img
                          id={'img' + i}
                          height="100%"
                          width="100%"
                          src={thumbnail.src}
                          data-caption=""
                          alt={thumbnail.alt || 'thumbnail'}
                        />
                      </Thumbnail>
                    )
                  }
                })
              : null}
          </ThumbnailsArea>
        </InfoArea>
      </CenterH>
      <DescriptionArea>
        <TitleText>Description</TitleText>
        <PriceText>{product.description}</PriceText>
      </DescriptionArea>
      <div
        onClick={() => toggleSize('')}
        className="thumbModal"
        id="thumbModal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}>
        <div>
          <img alt="screenshot" id="modalImage" className="modalImage" src="" />
        </div>
      </div>
    </>
  )
}

export default StoreDetails
