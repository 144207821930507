const firebaseConfig = {
  apiKey: 'AIzaSyBmHX-YbemzjJ-88JosqMeEplOXfFm78mU',
  authDomain: 'gms-website-staging-eaf4f.firebaseapp.com',
  databaseURL: 'https://gms-website-staging-eaf4f.firebaseio.com',
  projectId: 'gms-website-staging-eaf4f',
  storageBucket: 'gms-website-staging-eaf4f.appspot.com',
  messagingSenderId: '386729344927',
  appId: '1:386729344927:web:1c4f50db57f45abee487aa',
  measurementId: 'G-LTPZSRTZRF',
}

export default firebaseConfig
