import React from 'react'

import styled from 'styled-components'
import { CenterLeft } from './layout'

const Frame = styled.div`
  background-color: rgb(15, 15, 15);
  color: darkgrey;
  margin-top: 105px;
  padding: 20px;
  @media screen and (max-width: 1374px) {
    margin-top: 30px;
  }
`

const SourceArea = styled.div`
  padding-left: 10px;
`

const SourceText = styled.div`
  font-size: 12px;
`

const TitleText = styled.div`
  font-size: 30px;
  margin-bottom: 30px;
`

interface Props {
  width: number
  height: number
  features: Array<string>
}

const Features = ({ width, height, features }: Props) => {
  return (
    <Frame style={{ width, height }}>
      <div>
        <TitleText>Game Features</TitleText>
        {features.map((feature, i) => {
          return (
            <div key={i}>
              <SourceArea>
                <SourceText>- {feature}</SourceText> <br />
              </SourceArea>
            </div>
          )
        })}
      </div>
    </Frame>
  )
}

export default Features
