import React from 'react'

import { Link } from 'react-router-dom'

import './hamburger.css'
import { ReactComponent as Hamburger } from '../assets/bars-solid.svg'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components'

import GMSLogo from '../assets/GMS_Logo.svg'
import HeartIconSmall from '../assets/HeartIconSmall.png'
import Cart from '../components/cart'
import TopNavBarTransparent from '../assets/TopNavBarTransparent.png'

const branch = process.env.REACT_APP_BRANCH

const Header = styled.div`
  position: relative;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: white;
`
const MenuLarge = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1;
`

const TopNavBarImage = styled.img``

const Logo = styled(Link)`
  position: absolute;
  z-index: 1;
  margin-left: 60px;
  padding: 4px;
`

const NavBarMenu = styled.div`
  position: absolute;
  z-index: 1;
  margin-left: 400px;
  @media screen and (max-width: 750px) {
    display: none;
    margin: 0 24px;
  }
`

const MenuItem = styled.div`
  padding-top: 8px;
  color: white;
  &:hover {
    color: red;
    cursor: pointer;
  }
  @media screen and (min-width: 750px) {
    display: inline-block;
    margin: 0 24px;
  }
`

const StyledLink = styled(Link)`
  z-index: 1;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

const MenuSmall = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 8px 0;
  @media screen and (min-width: 750px) {
    display: none;
  }
`

export const HeaderComponent = () => {
  const toggleActive = () => {
    const nav = document.querySelector('.nav-links')
    nav?.classList.toggle('nav-active')
  }

  const navigation = (
    <>
      <StyledLink to="/news">
        <MenuItem>News</MenuItem>
      </StyledLink>
      <StyledLink to="/teamwork">
        <MenuItem>Partners</MenuItem>
      </StyledLink>
      {/* <StyledLink to="/store">
        <MenuItem>Store</MenuItem>
      </StyledLink> */}

      {branch === 'staging' ? (
        <>
          <StyledLink to="/built">
            <MenuItem>Built&Crafted</MenuItem>
          </StyledLink>
          <StyledLink to="/wehearttech">
            <MenuItem>
              <img src={HeartIconSmall} alt="We Heart" /> Tech
            </MenuItem>
          </StyledLink>
          <StyledLink to="/vr">
            <MenuItem>VR</MenuItem>
          </StyledLink>
          <StyledLink to="/connect">
            <MenuItem>Connect</MenuItem>
          </StyledLink>
          <StyledLink to="/instagram">
            <MenuItem>Instagram</MenuItem>
          </StyledLink>
          <MenuItem>
            <Cart />
          </MenuItem>
        </>
      ) : null}
    </>
  )

  const pathname = useLocation().pathname

  return (
    <Header>
      <MenuLarge>
        <TopNavBarImage src={TopNavBarTransparent} alt="TopNav" />
        <Hamburger className="burger" onClick={toggleActive}></Hamburger>
        <nav>
          <ul id="nav-links" className="nav-links" onClick={toggleActive}>
            {navigation}
          </ul>
        </nav>
        <Logo to="/">
          <img width="224" src={GMSLogo} alt="logo" id="gms-logo" />
        </Logo>
        <NavBarMenu>{navigation}</NavBarMenu>
      </MenuLarge>

      {pathname !== '/' ? <div style={{ height: 127 }}></div> : null}
    </Header>
  )
}

export default HeaderComponent
