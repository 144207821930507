import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Center, CenterBottom, CenterH, CenterHSpace } from '../components/layout'
import Card from '../components/card'
import { AssetContext } from '../contexts/assetContext'
import Footer from '../components/footer'

const VideoContainer = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(64, 64, 64, 1) 0%, rgba(0, 0, 0, 1) 100%);
  max-height: 900px;
  width: 100%;
  height: 55.55vw;
`

const HeroVideo = styled.video`
  position: absolute;
  height: auto;
  width: 100%;
`

const Text = styled.div`
  color: white;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 2em;
  text-shadow: 2px 2px #000;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 16px;
`

const NewsFeedTitle = styled.div`
  color: white;
  font-size: 48px;
  margin: 32px;
  font-weight: bold;
`

const NewsFeed = styled.div`
  padding-bottom: 64px;
`

const BottomRight = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  right: 0;
  bottom: 0;
  position: absolute;
  @media screen and (min-width: 1600px) {
    zoom: 1;
    -moz-transform: scale(1);
  }
  @media screen and (max-width: 1300px) {
    zoom: 0.75;
    -moz-transform: scale(0.75);
  }
  @media screen and (max-width: 1100px) {
    zoom: 0.5;
    -moz-transform: scale(0.5);
  }
  @media screen and (max-width: 875px) {
    zoom: 0.35;
    -moz-transform: scale(0.35);
  }
`

const News = () => {
  const assets: any = useContext(AssetContext)
  const { heroVideo, newsFeed, platformImages }: any = assets

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <VideoContainer>
          <HeroVideo src={heroVideo} autoPlay muted loop />
          <BottomRight>
            <Text>
              <CenterH>
                <div style={{ fontSize: 34, padding: '8px 0' }}>Available Now</div>
              </CenterH>
              <CenterH>
                <div>
                  {platformImages.map((platform: any, i: number) => {
                    return (
                      <img
                        style={{ padding: '0 16px', height: 40 }}
                        key={i}
                        src={platform.logoImage}
                        alt={platform.platform}
                      />
                    )
                  })}
                </div>
              </CenterH>
            </Text>
          </BottomRight>
        </VideoContainer>
      </div>
      <Center>
        <NewsFeedTitle>NEWS</NewsFeedTitle>
      </Center>
      <NewsFeed>
        <CenterH style={{ flexWrap: 'wrap' }}>
          {newsFeed.map((news: any, index: number) => {
            return (
              <Card
                key={index}
                width="400px"
                height="100%"
                text={news.text}
                date={news.date}
                title={news.title}
                image={news.image}
                url={news.url}
              />
            )
          })}
        </CenterH>
      </NewsFeed>
      <Center>
        <NewsFeedTitle>About Us</NewsFeedTitle>
        <div style={{ color: 'white', fontSize: 24, maxWidth: 800, padding: '0px 32px', textAlign: 'center' }}>
          There is one thing that connects the entire team here at GMS - we are all gamers at heart. At Game Mechanic,
          we are passionate about building a new, diverse, and innovative approach to games. With a love for technology
          and music, we strive to create experiences that you’ve never played before. Our team is made up of natural
          problem solvers who face every challenge head on with excitement. Our love for gaming is the core of what we
          do and the outcome of what we create is our rewards. We have been in business for 13 years and have a much
          longer journey ahead, please join us in creating a new world order of gaming.
        </div>
      </Center>
      <Footer showLegal={true} showWizardsPressKit={true} />
    </div>
  )
}

export default News
