import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

const Frame = styled.div`
  background-color: white;
  margin: 24px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`
const Image = styled(Link)`
  height: 70%;
`
const Text = styled.div`
  height: 30%;
  padding-left: 10px;
  text-align: center;
  font-size: 13px;
`

const Title = styled.div`
  background-color: white;
  font-weight: 700;
  padding-left: 10px;
  font-size: 14px;
  text-align: center;
`

const Button = styled.div`
  text-decoration: none;
  color: white;
  background-color: red;
  height: 10%;
  width: 50%;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 55px;
  margin-bottom: 5px;
  cursor: pointer;
`

interface Props {
  width: number
  height: number
  title: string
  posterImage: string
  comingSoon: boolean
  id: string
  inStock: boolean
}

const StoreCard = ({ width, height, title, posterImage, comingSoon, id, inStock }: Props) => {
  return (
    <Frame style={{ width, height }}>
      <Image to={`/storedetails/${id}`}>
        <img height="100%" width="100%" src={posterImage} alt="squarePoster" />
      </Image>
      <Title>
        {title} <br />
      </Title>

      {/* {inStock ? <Button>Add to Cart</Button> : null} */}
    </Frame>
  )
}

export default StoreCard
