import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CenterH, Center } from '../components/layout'
import StoreCard from '../components/storeCard'
import { AssetContext } from '../contexts/assetContext'
import { ShopifyContext } from '../contexts/shopifyContext'

const Featured = styled.div`
  background-color: rgb(128, 128, 128);
  margin: 20px;
  @media screen and (min-width: 1600px) {
    width: 1080px;
    height: 607px;
  }
`

const Store = () => {
  const assets: any = useContext(AssetContext)
  const { gamesFeed, widePosterImage }: any = assets
  const shop: any = useContext(ShopifyContext)
  const { getProducts }: any = shop

  const [products, setProducts] = useState([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    async function fetchProducts() {
      if (!loaded) {
        setLoaded(true)
        const response = await getProducts()
        return setProducts(response)
      }
    }
    fetchProducts()
  }, [products])

  return (
    <div>
      <CenterH>
        <Featured>
          <Center>
            <img height="100%" width="100%" src={widePosterImage} alt="wideposter" />
          </Center>
        </Featured>
      </CenterH>
      <CenterH style={{ flexWrap: 'wrap' }}>
        {products.map((game: any, index: number) => {
          return (
            <StoreCard
              key={index}
              width={250}
              height={350}
              title={game.title}
              posterImage={game.images[0].src}
              comingSoon={true}
              id={game.id}
              inStock={game.availableForSale}
            />
          )
        })}
      </CenterH>
    </div>
  )
}

export default Store
