import React, { createContext, FunctionComponent } from 'react'

import NewsGMS from '../assets/NewsGMS.png'
import NewsImmersion from '../assets/NewsImmersion.png'
import NewsMisbits from '../assets/NewsMisbits.png'
import NewsWalkingDead from '../assets/NewsWalkingDead.png'
import NewsWaysun from '../assets/NewsWaysun.png'
import NewsBurningManTheInfinitePlaya from '../assets/NewsBurningManTheInfinitePlaya.png'
import newsHTTeaser from '../assets/newsHTTeaser.png'
import NewsWizards from '../assets/tilt5wizardsuse.png'
import NewsTonyHawk from '../assets/NewsTonyHawk.png'
import OurStoryPlate from '../assets/OurStoryPlate.png'

import WizardsPoster01 from '../assets/BuiltAndCrafted/WizardsPoster01.png'
import RagePoster01 from '../assets/BuiltAndCrafted/RagePoster01.png'
import HappyTrailsPoster01 from '../assets/BuiltAndCrafted/HappyTrailsPoster01.png'

import EpicStoreLogo from '../assets/BuiltAndCrafted/EpicStoreLogo.png'
import NintendoLogo from '../assets/BuiltAndCrafted/NintendoLogo.png'
import Ps4Logo from '../assets/BuiltAndCrafted/Ps4Logo.png'
import XboxLogo from '../assets/BuiltAndCrafted/XboxLogo.png'
import XboxStoreLogo from '../assets/BuiltAndCrafted/XboxStoreLogo.png'

import EpicStoreLogoWide from '../assets/EpicLogo_wide.png'
import NintendoLogoWide from '../assets/NintendoSwitchLogo_wide.png'
import Ps4LogoWide from '../assets/PS4Logo_wide.png'
import XboxLogoWide from '../assets/XboxLogo_wide.png'
import SteamLogoWide from '../assets/SteamLogo_wide.png'

import WizardsGameBanner01 from '../assets/BuiltAndCrafted/WizardsGameBanner01.png'
import WizardsPoster02 from '../assets/BuiltAndCrafted/WizardsPoster02.png'
import WizardsPoster03 from '../assets/BuiltAndCrafted/WizardsPoster03.png'
import WizardsScreenshot from '../assets/wizards/WizardsScreenshot.png'
import WizardsScreenshot01 from '../assets/BuiltAndCrafted/WizardsScreenshot01.png'
import WizardsScreenshot02 from '../assets/BuiltAndCrafted/WizardsScreenshot02.png'
import WizardsScreenshot03 from '../assets/BuiltAndCrafted/WizardsScreenshot03.png'
import WizardsScreenshot04 from '../assets/BuiltAndCrafted/WizardsScreenshot04.png'
import WizardsScreenshot05 from '../assets/BuiltAndCrafted/WizardsScreenshot05.png'
import WizardsPlate from '../assets/BuiltAndCrafted/WizardsPlate.png'
import NintendoSwitchLogoVertical from '../assets/NintendoSwitchLogoVertical.png'
import PS4LogoVertical from '../assets/PS4LogoVertical.png'
import XboxLogoVertical from '../assets/XboxLogoVertical.png'
import SteamLogoVertical from '../assets/SteamLogo.png'
import EpicLogoVertical from '../assets/EpicGamesLogo.png'

import GamesWizards01_1 from '../assets/GamesWizards01_1.png'
import GamesWizards02_1 from '../assets/GamesWizards02_1.png'
import GamesWizards03_1 from '../assets/GamesWizards03_1.png'

import BGPlateTech from '../assets/WeHeartTech/BGPlateTech.png'
import HeartIcon from '../assets/WeHeartTech/HeartIcon.png'
import TarsusTechPoster01 from '../assets/WeHeartTech/TarsusTechPoster01.png'
import WaysunTechPoster01 from '../assets/WeHeartTech/WaysunTechPoster01.png'

import TeamWork2K01 from '../assets/TeamWork/TeamWork2K01.png'
import TeamWork3Blackdot01 from '../assets/TeamWork/TeamWork3Blackdot01.png'
import TeamWorkActivision01 from '../assets/TeamWork/TeamWorkActivision01.png'
import TeamWorkBlvd01 from '../assets/TeamWork/TeamWorkBlvd01.png'
import TeamWorkDena01 from '../assets/TeamWork/TeamWorkDena01.png'
import TeamWorkGameMill01 from '../assets/TeamWork/TeamWorkGameMill01.png'
import TeamWorkPlaceholder01 from '../assets/TeamWork/TeamWorkPlaceholder01.png'
import TeamWorkSkyDance01 from '../assets/TeamWork/TeamWorkSkyDance01.png'
import TeamWorkWB01 from '../assets/TeamWork/TeamWorkWB01.png'

import TeamworkGMSlogo from '../assets/TeamworkNew/GMSlogoteamworkv2_h.png'
import Infiniteplayause from '../assets/TeamworkNew/infiniteplayause_h.png'
import Skydancelogo from '../assets/TeamworkNew/skydancelogov2center_h.png'
import Activisionlogo from '../assets/TeamworkNew/activisionlogohalfandhalfv1_h.png'
import Qualcomm from '../assets/TeamworkNew/qualcommusebig_h.png'
import Cinemoilogo from '../assets/TeamworkNew/cinemoilogov1_h.png'
import Paramount from '../assets/TeamworkNew/paramountusev2_h.png'
import Thq from '../assets/TeamworkNew/thqtile_h.png'
import Defiancelogo from '../assets/TeamworkNew/defiancelogouse_h.png'
import HDR from '../assets/TeamworkNew/hdrlogobigger_hgaming.jpg'

export const AssetContext = createContext({})

interface IProps {}

const AssetProvider: FunctionComponent<IProps> = ({ children }) => {
  const state = {
    heroVideo: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WizardsTrailer.mp4',
    heroLogo: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards/WizardsLogo.png',
    storyImage: OurStoryPlate,
    weHeartTechPlateImage: BGPlateTech,
    widePosterImage: WizardsScreenshot,
    heartIcon: HeartIcon,
    techs: [
      {
        id: '0',
        title: 'Waysun Games',
        text: 'A fully built system from the ground up',
        image: WaysunTechPoster01,
      },
      {
        id: '1',
        title: 'Tarsus',
        text: 'A fully built system from the ground up',
        image: TarsusTechPoster01,
      },
      // {
      //   id: '2',
      //   title: 'Games Portal',
      //   text: 'A fully built system from the ground up',
      //   image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/TechnologyTiletTarsus.png',
      // },
      // {
      //   id: '3',
      //   title: 'Immersion',
      //   text: 'A fully built system from the ground up',
      //   image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/TechnologyTiletTarsus.png',
      // },
      // {
      //   id: '4',
      //   title: 'Qualcomm',
      //   text: 'A fully built system from the ground up',
      //   image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/TechnologyTiletTarsus.png',
      // },
      // {
      //   id: '5',
      //   title: 'BLVD Facebook article',
      //   text: 'A fully built system from the ground up',
      //   image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/TechnologyTiletTarsus.png',
      // },
      // {
      //   id: '6',
      //   title: 'Eliminate: Apple keynote article',
      //   text: 'A fully built system from the ground up',
      //   image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/TechnologyTiletTarsus.png',
      // },
    ],
    teamWork: [
      { id: '1', image: HDR },
      { id: '2', image: Infiniteplayause },
      { id: '3', image: Skydancelogo },
      { id: '4', image: Activisionlogo },
      { id: '5', image: Qualcomm },
      { id: '6', image: Cinemoilogo },
      { id: '7', image: Paramount },
      { id: '8', image: Thq },
      { id: '9', image: TeamWorkWB01 },
      { id: '10', image: Defiancelogo },
      { id: '11', image: TeamWorkDena01 },
      { id: '12', image: TeamWork2K01 },
      { id: '13', image: TeamWork3Blackdot01 },
      { id: '14', image: TeamWorkGameMill01 },
      { id: '15', image: TeamWorkBlvd01 },
    ],
    newsFeed: [
      {
        text: "Tony Hawk's Pro Skater 1 + 2 Remastered",
        image: NewsTonyHawk,
        title: 'GMS News',
        date: '5.15.20',
        url: 'https://www.gamespot.com/articles/tony-hawks-pro-skater-1-2-remaster-preorder-guide-/1100-6477162/',
      },
      {
        text: 'Steam Update for Wizards: Wand of Epicosity adding AR coming 2022.',
        image: NewsWizards,
        title: 'GMS News',
        date: '1.12.22',
      },
      {
        text: 'Game Mechanic Studios launches new site.',
        image: NewsGMS,
        title: 'GMS News',
        date: '3.23.20',
      },
      {
        text: 'The Burning Man Project launches The Infinite Playa - available 09/04/2020',
        image: NewsBurningManTheInfinitePlaya,
        title: 'GMS News',
        date: '9.4.2020',
        url: 'https://www.infiniteplaya.com/',
      },
      {
        text: 'The Walking Dead: Saints & Sinners',
        image: NewsWalkingDead,
        title: 'GMS News',
        date: '1.23.20',
        url: 'https://vrwalkingdead.com/',
      },
      {
        text: 'Coming 2021',
        image: newsHTTeaser,
        title: 'GMS News',
        date: '2021',
      },
    ],
    gamesFeed: [
      {
        id: '0',
        gameLogo: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards/WizardsLogo.png',
        title: 'Wizards: Wand of Epicosity',
        splashImage: WizardsScreenshot,
        posterSquare: WizardsPoster02,
        bannerPlate: WizardsPlate,
        posterImage: WizardsPoster01,
        bannerImage: WizardsGameBanner01,
        plainPosterImage: WizardsPoster03,
        videoUrl: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards_SM_+07.mp4',
        comingSoon: true,
        releaseDate: '2020',
        summary: 'Wizards: Wand of Epicosity is a tower defense game updated for console!',
        extendedSummary:
          'When the Wizard of Darkness awakened, it was up to one man to defeat him. When that man was accidentally killed by one idiot, Tobuscus (or "person of extremely low intelligence" - gently put), the responsibility of becoming an AWESOME WIZARD fell onto him...by default.',
        reviews: [
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
        ],
        thumbnailImages: [
          { image: WizardsScreenshot01 },
          { image: WizardsScreenshot02 },
          { image: WizardsScreenshot03 },
          { image: WizardsScreenshot04 },
          { image: WizardsScreenshot05 },
        ],
        description: 'We are pleased to offer this beautiful boxed edition of Wizards.',
        platforms: [
          {
            availableNow: true,
            platform: 'PS4',
            price: 19.99,
            inStock: true,
            logoImage: Ps4Logo,
          },
          {
            availableNow: true,
            platform: 'NINTENDO SWITCH',
            price: 19.99,
            inStock: true,
            logoImage: NintendoLogo,
          },
          {
            availableNow: true,
            platform: 'XBOX ONE',
            price: 19.99,
            inStock: false,
            logoImage: XboxLogo,
          },
          {
            availableNow: false,
            platform: 'XBOX STORE',
            price: 19.99,
            inStock: false,
            logoImage: XboxStoreLogo,
          },
          {
            availableNow: false,
            platform: 'EPIC STORE',
            price: 19.99,
            inStock: false,
            logoImage: EpicStoreLogo,
          },
        ],
        features: [
          'Updated graphics and controls for the console!',
          'Play as Tobuscus and unlock new spells, wands, and potions as you level up!',
          'Battle tons of zombies, liches, and other evil creatures on 20 different maps across the Frozen Forest and Molten mountains!',
          "Upgrade your Tower and Wizard Gear...or else you'll probably die!",
          'Exclusive animated scenes that will bring you into the game in a way no other game has ever done as awesomely anywhere ever before!',
          'Launch your faithful dog that trusts you -Gryphon- from a cannon directly into battle to plant bombs and explode herds of zombies!',
          'Gabascus is also in the game.(He made me include this.)',
        ],
      },
      {
        id: '1',
        gameLogo: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards/WizardsLogo.png',
        title: 'Rage of Titans',
        splashImage: WizardsScreenshot,
        posterSquare: WizardsPoster02,
        bannerPlate: WizardsPlate,
        posterImage: RagePoster01,
        bannerImage: WizardsGameBanner01,
        plainPosterImage: WizardsPoster03,
        videoUrl: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards_SM_+07.mp4',
        comingSoon: true,
        releaseDate: 'SOON',
        summary:
          'Wizards Wand of Epicosity is a party-based RPG in which you lead a band of exiles to freedom through an ancient competition spread across a vasy mystical purgatory.',
        extendedSummary:
          'When the Wizard of Darkness awakened, it was up to one man to defeat him. When tat man was accidently killed by one idiot, Tobuscus (or "person of extremely low intelligence" - gently put), the responsibility of becoming an AWESOME WIZARD fell onto him...by default.',
        reviews: [
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: 'https://www.gamespot.com/',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: 'https://www.gamespot.com/',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: 'https://www.gamespot.com/',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: 'https://www.gamespot.com/',
          },
        ],
        thumbnailImages: [
          { image: WizardsScreenshot01 },
          { image: WizardsScreenshot02 },
          { image: WizardsScreenshot03 },
          { image: WizardsScreenshot04 },
          { image: WizardsScreenshot05 },
        ],
        description: 'We are pleased to offer this beautiful boxed edition of Wizards.',
        platforms: [
          {
            availableNow: true,
            platform: 'PS4',
            price: 19.99,
            inStock: true,
            logoImage: Ps4Logo,
          },
          {
            availableNow: true,
            platform: 'NINTENDO SWITCH',
            price: 19.99,
            inStock: true,
            logoImage: NintendoLogo,
          },
          {
            availableNow: true,
            platform: 'XBOX ONE',
            price: 19.99,
            inStock: false,
            logoImage: XboxLogo,
          },
          {
            availableNow: false,
            platform: 'XBOX STORE',
            price: 19.99,
            inStock: true,
            logoImage: XboxStoreLogo,
          },
          {
            availableNow: false,
            platform: 'EPIC STORE',
            price: 19.99,
            inStock: true,
            logoImage: EpicStoreLogo,
          },
        ],
        features: [
          'Updated graphics and controls for the console!',
          'Play as Tobuscus and unlock new spells, wands, and potions as you level up!',
          'Battle tons of zombies, liches, and other evil creatures on 20 different maps across the Frozen Forest and Molten mountains!',
          "Upgrade your Tower and Wizard Gear...or else you'll probably die!",
          'Exclusive animated scenes that will bring you into the game in a way no other game has ever done as awesomely anywhere ever before!',
          'Launch your faithful dog that trusts you -Gryphon- from a cannon directly into battle to plant bombs and explode herds of zombies!',
          'Gabascus is also in the game.(He made me include this.)',
        ],
      },
      {
        id: '2',
        gameLogo: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards/WizardsLogo.png',
        title: 'Happy Trails',
        splashImage: WizardsScreenshot,
        posterSquare: WizardsPoster02,
        bannerPlate: WizardsPlate,
        posterImage: HappyTrailsPoster01,
        bannerImage: WizardsGameBanner01,
        plainPosterImage: WizardsPoster03,
        videoUrl: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards_SM_+07.mp4',
        comingSoon: true,
        releaseDate: 'SOON',
        summary:
          'Wizards Wand of Epicosity is a party-based RPG in which you lead a band of exiles to freedom through an ancient competition spread across a vasy mystical purgatory.',
        extendedSummary:
          'When the Wizard of Darkness awakened, it was up to one man to defeat him. When tat man was accidently killed by one idiot, Tobuscus (or "person of extremely low intelligence" - gently put), the responsibility of becoming an AWESOME WIZARD fell onto him...by default.',
        reviews: [
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
        ],
        thumbnailImages: [
          { image: WizardsScreenshot01 },
          { image: WizardsScreenshot02 },
          { image: WizardsScreenshot03 },
          { image: WizardsScreenshot04 },
          { image: WizardsScreenshot05 },
        ],
        description: 'We are pleased to offer this beautiful boxed edition of Wizards.',
        platforms: [
          {
            availableNow: true,
            platform: 'PS4',
            price: 19.99,
            inStock: true,
            logoImage: Ps4Logo,
          },
          {
            availableNow: true,
            platform: 'NINTENDO SWITCH',
            price: 19.99,
            inStock: true,
            logoImage: NintendoLogo,
          },
          {
            availableNow: true,
            platform: 'XBOX ONE',
            price: 19.99,
            inStock: false,
            logoImage: XboxLogo,
          },
          {
            availableNow: false,
            platform: 'XBOX STORE',
            price: 19.99,
            inStock: true,
            logoImage: XboxStoreLogo,
          },
          {
            availableNow: false,
            platform: 'EPIC STORE',
            price: 19.99,
            inStock: true,
            logoImage: EpicStoreLogo,
          },
        ],
        features: [
          'Updated graphics and controls for the console!',
          'Play as Tobuscus and unlock new spells, wands, and potions as you level up!',
          'Battle tons of zombies, liches, and other evil creatures on 20 different maps across the Frozen Forest and Molten mountains!',
          "Upgrade your Tower and Wizard Gear...or else you'll probably die!",
          'Exclusive animated scenes that will bring you into the game in a way no other game has ever done as awesomely anywhere ever before!',
          'Launch your faithful dog that trusts you -Gryphon- from a cannon directly into battle to plant bombs and explode herds of zombies!',
          'Gabascus is also in the game.(He made me include this.)',
        ],
      },
      {
        id: '3',
        gameLogo: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards/WizardsLogo.png',
        title: 'H4S',
        splashImage: WizardsScreenshot,
        posterSquare: WizardsPoster02,
        bannerPlate: WizardsPlate,
        posterImage: HappyTrailsPoster01,
        bannerImage: WizardsGameBanner01,
        plainPosterImage: WizardsPoster03,
        videoUrl: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/Wizards_SM_+07.mp4',
        comingSoon: true,
        releaseDate: 'SOON',
        summary:
          'Wizards Wand of Epicosity is a party-based RPG in which you lead a band of exiles to freedom through an ancient competition spread across a vasy mystical purgatory.',
        extendedSummary:
          'When the Wizard of Darkness awakened, it was up to one man to defeat him. When tat man was accidently killed by one idiot, Tobuscus (or "person of extremely low intelligence" - gently put), the responsibility of becoming an AWESOME WIZARD fell onto him...by default.',
        reviews: [
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
          {
            text: 'Wizards enchants your eyes and ears with beauty at every turn.',
            source: 'GameSpot',
            score: '9.0 / 10',
            url: '',
          },
        ],
        thumbnailImages: [
          { image: WizardsScreenshot01 },
          { image: WizardsScreenshot02 },
          { image: WizardsScreenshot03 },
          { image: WizardsScreenshot04 },
          { image: WizardsScreenshot05 },
        ],
        description: 'We are pleased to offer this beautiful boxed edition of Wizards.',
        platforms: [
          {
            availableNow: true,
            platform: 'PS4',
            price: 19.99,
            inStock: true,
            logoImage: Ps4Logo,
          },
          {
            availableNow: true,
            platform: 'NINTENDO SWITCH',
            price: 19.99,
            inStock: true,
            logoImage: NintendoLogo,
          },
          {
            availableNow: true,
            platform: 'XBOX ONE',
            price: 19.99,
            inStock: false,
            logoImage: XboxLogo,
          },
          {
            availableNow: false,
            platform: 'XBOX STORE',
            price: 19.99,
            inStock: true,
            logoImage: XboxStoreLogo,
          },
          {
            availableNow: false,
            platform: 'EPIC STORE',
            price: 19.99,
            inStock: true,
            logoImage: EpicStoreLogo,
          },
        ],
        features: [
          'Updated graphics and controls for the console!',
          'Play as Tobuscus and unlock new spells, wands, and potions as you level up!',
          'Battle tons of zombies, liches, and other evil creatures on 20 different maps across the Frozen Forest and Molten mountains!',
          "Upgrade your Tower and Wizard Gear...or else you'll probably die!",
          'Exclusive animated scenes that will bring you into the game in a way no other game has ever done as awesomely anywhere ever before!',
          'Launch your faithful dog that trusts you -Gryphon- from a cannon directly into battle to plant bombs and explode herds of zombies!',
          'Gabascus is also in the game.(He made me include this.)',
        ],
      },
    ],
    termsOfService:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel egestas magna. Phasellus faucibus a dui id ullamcorper. Nam vitae eros metus. Donec ut venenatis libero. Nulla auctor porta erat, vel fermentum metus condimentum id. Ut non dapibus libero, ut aliquam turpis. Vivamus placerat magna ac ex egestas, vel varius velit tincidunt. Sed at elementum ligula, vitae condimentum orci. Morbi ut neque ipsum. Duis pulvinar erat et lobortis fermentum. Praesent erat felis, accumsan quis pretium non, scelerisque sit amet justo. Sed neque ipsum, aliquet eget enim et, auctor auctor arcu. Sed id aliquet est. Phasellus imperdiet iaculis odio, nec maximus purus condimentum vitae.',
    privacyPolicy:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel egestas magna. Phasellus faucibus a dui id ullamcorper. Nam vitae eros metus. Donec ut venenatis libero. Nulla auctor porta erat, vel fermentum metus condimentum id. Ut non dapibus libero, ut aliquam turpis. Vivamus placerat magna ac ex egestas, vel varius velit tincidunt. Sed at elementum ligula, vitae condimentum orci. Morbi ut neque ipsum. Duis pulvinar erat et lobortis fermentum. Praesent erat felis, accumsan quis pretium non, scelerisque sit amet justo. Sed neque ipsum, aliquet eget enim et, auctor auctor arcu. Sed id aliquet est. Phasellus imperdiet iaculis odio, nec maximus purus condimentum vitae.',
    vrGames: [
      {
        id: '0',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '1',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '2',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '3',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '4',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '5',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '6',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '7',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '8',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '9',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '10',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
      {
        id: '11',
        image: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/WalkingDeadVrPoster.png',
      },
    ],
    homeGames: [
      {
        id: '12',
        image: GamesWizards01_1,
      },
      {
        id: '13',
        image: GamesWizards02_1,
      },
      {
        id: '14',
        image: GamesWizards03_1,
      },
    ],
    homeGamesLinks: [
      { image: Ps4LogoWide, url: 'https://store.playstation.com/en-us/product/UP0279-CUSA11797_00-0000000000000000' },
      {
        image: XboxLogoWide,
        url:
          'https://www.microsoft.com/en-us/p/wizards-wand-of-epicosity/9n5vvxgmfxkj?ocid=AID2000142_aff_7593_1238890&tduid=(ir__zb6xydog2gkft2rfkk0sohziz32ximleodo2y0n600)(7593)(1238890)()()&irgwc=1&irclickid=_zb6xydog2gkft2rfkk0sohziz32ximleodo2y0n600&activetab=pivot:overviewtab',
      },
      { image: NintendoLogoWide, url: 'https://www.nintendo.com/games/detail/wizards-wand-of-epicosity-switch' },
      { image: EpicStoreLogoWide, url: 'https://www.epicgames.com/store/en-US/product/wizards-wand-of-epicosity/home' },
      { image: SteamLogoWide, url: 'https://store.steampowered.com/app/772750/Wizards_Wand_of_Epicosity' },
    ],
    platformImages: [
      {
        platform: 'PS4',
        logoImage: PS4LogoVertical,
      },
      {
        platform: 'NINTENDO SWITCH',
        logoImage: NintendoSwitchLogoVertical,
      },
      {
        platform: 'XBOX ONE',
        logoImage: XboxLogoVertical,
      },
      {
        platform: 'EPIC',
        logoImage: EpicLogoVertical,
      },
      {
        platform: 'STEAM',
        logoImage: SteamLogoVertical,
      },
      // {
      //   platform: 'STEAM',
      //   logoImage: 'https://gms-website-assets.s3-us-west-2.amazonaws.com/SteamLogo.png'
      // }
    ],
  }

  return <AssetContext.Provider value={state}>{children}</AssetContext.Provider>
}

export default AssetProvider
