import React, { useContext } from 'react'
import { Center, CenterH, Banner } from '../components/layout'
import Card from '../components/card'
import styled from 'styled-components'

import { AssetContext } from '../contexts/assetContext'

const LeftPanel = styled.div`
  margin-right: 200px;
  padding: 30px;
  height: 365px;
  width: 649px;
  padding-bottom: 140px;
  @media screen and (max-width: 950px) {
    height: 273px;
    width: 486px;
    margin-right: 150px;
  }
  @media screen and (max-width: 725px) {
    height: 182px;
    width: 324px;
    margin-right: 0px;
  }
`

const RightPanel = styled.div`
  margin-left: 200px;
  padding: 30px;
  height: 365px;
  width: 649px;
  padding-bottom: 140px;

  @media screen and (max-width: 950px) {
    height: 273px;
    width: 486px;
    margin-left: 150px;
  }
  @media screen and (max-width: 725px) {
    height: 182px;
    width: 324px;
    margin-left: 0px;
  }
`

const HeartIcon = styled.img`
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (min-width: 1600px) {
    height: 40px;
  }
  @media screen and (max-width: 1600px) {
    height: 40px;
  }
  @media screen and (max-width: 730px) {
    height: 28px;
  }
`

const WeHeartTech = () => {
  const assets: any = useContext(AssetContext)
  const { techs, weHeartTechPlateImage, heartIcon }: any = assets
  return (
    <div style={{ backgroundImage: `url(${weHeartTechPlateImage})` }}>
      <Banner>
        <CenterH>
          We <HeartIcon src={heartIcon} alt="(heart)" /> Technology
        </CenterH>
      </Banner>
      <CenterH style={{ flexWrap: 'wrap' }}>
        {techs.map((tech: any, index: number) => {
          if (index % 2 === 0) {
            return (
              <LeftPanel key={index}>
                <Card
                  title={tech.title}
                  text={tech.text}
                  date={''}
                  height="100%"
                  width="100%"
                  image={tech.image}
                  url={''}
                  screen="tech"
                />
              </LeftPanel>
            )
          } else {
            return (
              <RightPanel key={index}>
                <Card
                  title={tech.title}
                  text={tech.text}
                  date={''}
                  height="100%"
                  width="100%"
                  image={tech.image}
                  url={''}
                  screen="tech"
                />
              </RightPanel>
            )
          }
        })}
      </CenterH>
    </div>
  )
}

export default WeHeartTech
