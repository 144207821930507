import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { platform } from 'os'

const Frame = styled.div`
  background-color: rgb(200, 200, 200);
  border: 1px solid black;
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  background-size: 'cover',
  background-position: 'center'
`

const PosterImage = styled.div`
  background-color: darkgray;
  display: flex;
  flex-direction: row;
  height: 512px;
  width: 512px;
  margin: 16px;
  @media screen and (max-width: 1600px) {
    height: 384px;
    width: 384px;
    margin: 32px;
  }
  @media screen and (max-width: 900px) {
    height: 256px;
    width: 256px;
    margin: 32px;
  }
  @media screen and (max-width: 700px) {
    height: 384px;
    width: 384px;
    margin: 32px;
  }
`
const WhiteFont = styled.div`
  color: white;
  padding-top: 20px;
  padding-bottom: 16px;
`

const Title = styled.div`
  font-size: 30px;
  padding-top: 100px;
  @media screen and (max-width: 1090px) {
    font-size: 20px;
  }
  @media screen and (max-width: 900px) {
    padding-top: 50px;
  }
  @media screen and (max-width: 700px) {
    padding-top: 0px;
  }
`
const SummaryWrap = styled.div`
  color: white;
  width: 70%;
  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }
  @media screen and (max-width: 574px) {
    font-size: 14px;
  }
`
const Footer = styled.div`
  color: white;
  padding-top: 100px;
  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }
  @media screen and (max-width: 900px) {
    padding-top: 50px;
    font-size: 12px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

const RedLine = styled.div`
  width: 100%;
  border-bottom: 2px solid red;
  margin-bottom: 20px;
`

const DetailsArea = styled.div`
  width: 1000px;
  padding-left: 20px;
  @media screen and (max-width: 1600px) {
    width: calc(100% - 500px);
  }
  @media screen and (max-width: 900px) {
    width: calc(100% - 350px);
  }
  @media screen and (max-width: 700px) {
    width: calc(100%);
  }
`

interface Props {
  title: string
  posterSquare: string
  bannerPlate: string
  comingSoon: boolean
  summary: string
  platforms: Array<string>
  id: string
  releaseDate: string
}

const GameTile = ({ title, posterSquare, bannerPlate, comingSoon, summary, platforms, id, releaseDate }: Props) => {
  const platformsLength = platforms.length

  return (
    <Frame
      style={{
        width: '100%',
        backgroundImage: `url(${bannerPlate})`,
      }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <StyledLink to={`/gameDetails/${id}`}>
          <PosterImage>
            <img width="100%" height="100%" src={posterSquare} alt="text" />
          </PosterImage>
        </StyledLink>
        <DetailsArea>
          <WhiteFont>
            <Title>{title}</Title>
          </WhiteFont>
          <SummaryWrap>{summary}</SummaryWrap>
          <Footer>
            <RedLine />
            {comingSoon ? `COMING ${releaseDate}` : 'NOW AVAILABLE'}
            <br />
            <br />
            <div>
              {platforms.map((platform: any, i: number) => {
                if (platform.availableNow === true) {
                  return (
                    <img
                      style={{ paddingRight: 15, paddingBottom: 15 }}
                      key={i}
                      src={platform.logoImage}
                      alt={platform.platform}
                    />
                  )
                } else return null
              })}
            </div>
          </Footer>
        </DetailsArea>
      </div>
    </Frame>
  )
}

export default GameTile
