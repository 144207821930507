import React, { useContext } from 'react'
import styled from 'styled-components'
import { Center, CenterH, Banner } from '../components/layout'
import Card from '../components/card'
import GameTile from '../components/gameTile'
import { Link } from 'react-router-dom'

import { AssetContext } from '../contexts/assetContext'

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

const Crafted = () => {
  const assets: any = useContext(AssetContext)
  const { gamesFeed }: any = assets

  return (
    <div>
      <Banner>
        <Center>BUILT & CRAFTED BY GAME MECHANIC STUDIOS</Center>
      </Banner>
      <CenterH style={{ flexWrap: 'wrap' }}>
        {gamesFeed.map((game: any, index: number) => {
          return (
            <StyledLink key={index} to={`/gameDetails/${game.id}`}>
              <Card
                width="400px"
                height="100%"
                text={''}
                date={''}
                title={''}
                image={game.posterImage}
                screen="built"
                releaseDate={game.releaseDate}
              />
            </StyledLink>
          )
        })}
        {gamesFeed.map((game: any, index: number) => {
          return (
            <GameTile
              key={index}
              title={game.title}
              posterSquare={game.posterSquare}
              comingSoon={game.comingSoon}
              bannerPlate={game.bannerPlate}
              summary={game.summary}
              platforms={game.platforms}
              id={game.id}
              releaseDate={game.releaseDate}
            />
          )
        })}
      </CenterH>
    </div>
  )
}

export default Crafted
