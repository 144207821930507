import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Features from '../components/features'
import AboutTheGame from '../components/aboutTheGame'
import { CenterH, Banner } from '../components/layout'

import '../components/thumbModal.css'

import { AssetContext } from '../contexts/assetContext'
import styled from 'styled-components'

const ThumbnailsArea = styled.div`
  width: 90%;
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Thumbnail = styled.div`
  width: 441px;
  background-color: grey;
  margin: 10px;
  cursor: zoom-in;
  transition: opacity 0.4s;
`

const Video = styled.div`
  width: 441px;
  margin: 10px;
  cursor: pointer;
`

const GameDetailsPage = () => {
  const { id } = useParams()
  const gameId = id
  const assets: any = useContext(AssetContext)
  const { gamesFeed }: any = assets

  const game = gamesFeed.find(({ id }: any) => id === gameId)

  const toggleSize = (id: string) => {
    const modal = document.querySelector('.thumbModal')
    if (id !== '') {
      const img = document.getElementById(`${id}`) as HTMLImageElement
      const modalImage = document.getElementById('modalImage')
      if (modalImage) {
        modalImage.setAttribute('src', img.src)
      }
    }
    modal?.classList.toggle('active')
  }

  return (
    <div>
      <div style={{ flexWrap: 'wrap', display: 'flex' }}>
        <AboutTheGame
          extendedSummary={game.extendedSummary}
          platforms={game.platforms}
          comingSoon={game.comingSoon}
          plainPosterImage={game.plainPosterImage}
          bannerImage={game.bannerImage}
          thumbnails={game.thumbnails}
          videoUrl={game.videoUrl}
          releaseDate={game.releaseDate}
        />
        <Features height={480} width={480} features={game.features} />
        <Banner>
          <CenterH>SCREENSHOTS</CenterH>
        </Banner>
        <ThumbnailsArea>
          <Video>
            <video src={game.videoUrl} controls height="100%" width="100%" id={'img-vid'} />{' '}
          </Video>
          {game.thumbnailImages.map((thumbnail: any, i: number) => {
            return (
              <Thumbnail key={i} onClick={() => toggleSize('img' + i)}>
                <img id={'img' + i} height="100%" width="100%" src={thumbnail.image} data-caption="" alt="thumbnail" />
              </Thumbnail>
            )
          })}
        </ThumbnailsArea>
      </div>
      <CenterH>
        <div
          onClick={() => toggleSize('')}
          className="thumbModal"
          id="thumbModal"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}>
          <div>
            <img alt="screenshot" id="modalImage" className="modalImage" src="" />
          </div>
        </div>
      </CenterH>
    </div>
  )
}

export default GameDetailsPage
