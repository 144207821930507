import styled from 'styled-components'

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const CenterTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const CenterBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
export const CenterH = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`
export const CenterHSpace = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`

export const CenterLeft = styled.div`
  display: flex;
  left: 0;
  padding: 25px;
`

export const Banner = styled.div`
  width: 100%;
  margin: 32px 0;
  color: white;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 1600px) {
    font-size: 40px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 40px;
  }
  @media screen and (max-width: 730px) {
    font-size: 28px;
  }
`
