import React from 'react'

import { Link } from 'react-router-dom'

import styled from 'styled-components'

import GMSLogo from '../assets/GMS_Logo.svg'

import BottomNavBarTransparent from '../assets/BottomNavBarTransparent.png'

const Footer = styled.div`
  height: 123px;
  background-image: url(${BottomNavBarTransparent});
  background-position: right;
  background-repeat: no-repeat;
`

const Logo = styled(Link)`
  float: right;
  padding: 8px;
  width: 224px;
  @media screen and (max-width: 810px) {
    width: 150px;
    padding-top: 20px;
  }
  @media screen and (max-width: 452px) {
    width: 100px;
    padding-top: 30px;
  }
`

const StyledLink = styled(Link)`
  float: left;
  padding: 44px 16px;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  @media screen and (max-width: 810px) {
    padding: 0px 0px;
    display: block;
  }
  @media screen and (max-width: 452px) {
    width: 60px;
    padding-top: 0px;
  }
`
const StyledLinkPressKit = styled.div`
  float: left;
  padding: 44px 16px;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  @media screen and (max-width: 810px) {
    padding: 0px 0px;
    display: block;
  }
  @media screen and (max-width: 452px) {
    width: 60px;
    padding-top: 0px;
  }
`

const MenuItem = styled.div`
  float: right;
  padding: 40px 16px;
  color: white;
  font-size: 16px;
  &:hover {
    color: red;
    cursor: pointer;
  }
  @media screen and (max-width: 810px) {
    padding: 10px 0px;
  }
  @media screen and (max-width: 452px) {
    font-size: 10px;
  }
`

const CopyRight = styled.div`
  float: right;
  color: white;
  font-size: 10px;
  padding: 100px 0;
  @media screen and (max-width: 570px) {
    font-size: 8px;
  }
  @media screen and (max-width: 360px) {
    padding: 100px 0px;
    font-size: 6px;
  }
  @media screen and (max-width: 810px) {
    float: left;
  }
`

const LinkArea = styled.div`
  @media screen and (max-width: 810px) {
    display: block;
    width: 120px;
    float: right;
    padding: 30px 0px;
  }
  @media screen and (max-width: 452px) {
    width: 80px;
  }
`

export function FooterComponent({
  showLegal = false,
  showWizardsPressKit = false,
  showHappyTrailsPressKit = false,
}: {
  showLegal?: Boolean
  showWizardsPressKit?: Boolean
  showHappyTrailsPressKit?: Boolean
}) {
  return (
    <Footer>
      <Logo to="/">
        <img
          width="100%"
          style={{
            alignItems: 'end',
            paddingTop: '8px',
          }}
          src={GMSLogo}
          alt="logo"
          id="gms-logo"
        />
      </Logo>

      <LinkArea>
        {showLegal && (
          <>
            <StyledLink to="/termsofservice">
              <MenuItem>Terms of Service</MenuItem>
            </StyledLink>
            <StyledLink to="/privacypolicy">
              <MenuItem>Privacy Policy</MenuItem>
            </StyledLink>
          </>
        )}
        <StyledLinkPressKit>
          {showWizardsPressKit && (
            <a href={`${process.env.PUBLIC_URL}/WizardsPressKit.pdf`} download>
              <MenuItem>Wizards: Wand of Epicosity Press Kit</MenuItem>
            </a>
          )}
          {showHappyTrailsPressKit && (
            <a href={`${process.env.PUBLIC_URL}/HappyTrailsPressKit.pdf`} download>
              <MenuItem>Happy Press Kit</MenuItem>
            </a>
          )}
        </StyledLinkPressKit>
      </LinkArea>
      <CopyRight>COPYRIGHT 2020 GAME MECHANIC STUDIOS</CopyRight>
    </Footer>
  )
}

export default FooterComponent
