import React, { useEffect } from 'react'

import styled from 'styled-components'

import { HashRouter, Switch, Route, useLocation } from 'react-router-dom'

import Header from './components/header'
import Home from './routes/home'
import Story from './routes/story'
import Built from './routes/built'
import Connect from './routes/connect'
import Teamwork from './routes/teamWork'
import VR from './routes/vr'
import WeHeartTech from './routes/weHeartTech'
import GameDetailsPage from './routes/gameDetailsPage'
import Instagram from './routes/instagram'
import Store from './routes/store'
import StoreDetailsPage from './routes/storeDetailsPage'
import AssetProvider from './contexts/assetContext'
import ShopifyProvider from './contexts/shopifyContext'

import News from './routes/news'

import TermsOfService from './routes/termsOfService'
import PrivacyPolicy from './routes/privacyPolicy'

import firebase from 'firebase'
import firebaseConfig from './firebaseConfig'

firebase.initializeApp(firebaseConfig)

const branch = process.env.REACT_APP_BRANCH

const AppLayout = styled.div``

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => {
  return (
    <AppLayout>
      <HashRouter>
        <AssetProvider>
          <ShopifyProvider>
            <Header />
            <ScrollToTop />
            <Switch>
              {branch === 'staging' ? (
                <>
                  <Route path="/built" component={Built} />
                  <Route path="/connect" component={Connect} />
                  <Route path="/gamedetails/:id" component={GameDetailsPage} />
                  <Route path="/instagram" component={Instagram} />
                  <Route path="/storedetails/:id" component={StoreDetailsPage} />
                  <Route path="/story" component={Story} />
                  <Route path="/vr" component={VR} />
                  <Route path="/wehearttech" component={WeHeartTech} />
                  <Route path="/privacypolicy" component={PrivacyPolicy} />
                  <Route path="/termsofservice" component={TermsOfService} />
                  <Route exact path="/" component={Home} />
                </>
              ) : (
                <>
                  <Route path="/news" component={News} />
                  <Route path="/teamwork" component={Teamwork} />
                  <Route path="/store" component={Store} />
                  <Route path="/privacypolicy" component={PrivacyPolicy} />
                  <Route path="/termsofservice" component={TermsOfService} />
                  <Route exact path="/" component={Home} />
                </>
              )}
            </Switch>
          </ShopifyProvider>
        </AssetProvider>
      </HashRouter>
    </AppLayout>
  )
}

export default App
